import React, { createContext, Dispatch, ReactNode, useContext, useState } from 'react';

type SendReturnContextData = {
  checkedUserId: string | null;
  setCheckedUserId: Dispatch<React.SetStateAction<string | null>>;
};

type SendReturnProviderProps = {
  children: ReactNode;
};

const SendReturnContext = createContext<SendReturnContextData | undefined>(undefined);

export const useSendReturn = (): SendReturnContextData => {
  const context = useContext(SendReturnContext);
  if (context === undefined) {
    throw new Error('useSendReturn must be used within an SendReturnProvider');
  }
  return context;
};

export const SendReturnProvider = (props: SendReturnProviderProps) => {
  const { children } = props;
  const [checkedUserId, setCheckedUserId] = useState<string | null>(null);

  const value = {
    checkedUserId,
    setCheckedUserId,
  };

  return <SendReturnContext.Provider value={value}>{children}</SendReturnContext.Provider>;
};
