import axios from '@/api/axios';
import { IResponseResult } from '@/interfaces/common.interface';
import { TProjectReturn } from './type';

export class ProjectReturnApi {
  public static async getByProjectId(projectId: string): Promise<IResponseResult<TProjectReturn[]>> {
    const response = await axios.get('project-return', { params: { projectId } });
    return response.data;
  }
}
