import { IDetailData } from "@/module/users/type.interface";
import React from "react";

interface RowDetailProps {
  data: IDetailData;
}

const RowDetail: React.FC<RowDetailProps> = ({ data }) => {
  return <div className="w-full border-b-border-blue border-b-[1px] px-4">
    <p className="text-primary-400 font-bold text-base flex justify-starts pb-[5px]">{data.label}</p>
    <p className="text-neutral-850 font-bold text-base flex justify-end">{data.value}</p>
  </div>;
};

export default RowDetail;
