import Spinner from '@/components/atom/Spinner/Spinner';
import { cn } from '@/lib/utils';
import { createContext, FC, ReactNode, useRef, useState } from 'react';

type LoadingData = {
  isOpenLoading: boolean;
  openLoading: Function;
  closeLoading: Function;
};

export const LoadingContext = createContext<LoadingData>({
  isOpenLoading: false,
  openLoading: () => {},
  closeLoading: () => {},
});

type LoadingProviderProps = {
  children: ReactNode;
};

export const LoadingProvider: FC<LoadingProviderProps> = ({ children }) => {
  const [isOpenLoading, setIsOpenLoading] = useState(false);
  const loadingElement = useRef<HTMLDivElement>(null);

  const openLoading = () => setIsOpenLoading(true);
  const closeLoading = () => setIsOpenLoading(false);

  return (
    <LoadingContext.Provider value={{ isOpenLoading, openLoading, closeLoading }}>
      <div className="relative overflow-hidden">
        {children}
        <div
          ref={loadingElement}
          className={cn(
            isOpenLoading ? 'visible' : 'invisible',
            'absolute inset-0 z-[9999] flex h-screen w-screen items-center justify-center',
          )}
        >
          <div className="absolute h-screen w-screen bg-[#0000007e] opacity-70"></div>
          <Spinner />
        </div>
      </div>
    </LoadingContext.Provider>
  );
};
