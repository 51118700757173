import React, { createContext, Dispatch, ReactNode, useContext, useState } from 'react';

type IssueApContextData = {
  checkedUserId: string | null;
  setCheckedUserId: Dispatch<React.SetStateAction<string | null>>;
};

type IssueApProviderProps = {
  children: ReactNode;
};

const IssueApContext = createContext<IssueApContextData | undefined>(undefined);

export const useIssueAp = (): IssueApContextData => {
  const context = useContext(IssueApContext);
  if (context === undefined) {
    throw new Error('useIssueAp must be used within an IssueApProvider');
  }
  return context;
};

export const IssueApProvider = (props: IssueApProviderProps) => {
  const { children } = props;
  const [checkedUserId, setCheckedUserId] = useState<string | null>(null);

  const value = {
    checkedUserId,
    setCheckedUserId,
  };

  return <IssueApContext.Provider value={value}>{children}</IssueApContext.Provider>;
};
