import React, {  } from "react";

interface FormLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    required?: boolean;
    text: string;
}

const FormLabel = React.forwardRef<HTMLLabelElement, FormLabelProps>((props, ref) => {
  return (
    <label className="login-label text-primary-400 text-sm font-bold pb-2 flex gap-1">
        {props.text}
        {props.required ? <span className="text-red-500">*</span> : ''}
    </label>
  )
})

export default FormLabel;