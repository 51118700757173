import React, { ImgHTMLAttributes, memo, useEffect, useState } from "react";

interface IImageDisplay
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
  url: string | File;
}

const ImageDisplay: React.FC<IImageDisplay> = (props) => {
  const { url, ...rest } = props;

  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (typeof url === "string") {
      // If src is a string, use it directly
      setImageSrc(url);
    } else if (url instanceof File) {
      // If src is a File, use FileReader to convert it to a data url
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(url);
    }
  }, [url]);

  return <img src={imageSrc} {...rest} />;
};

export default memo(ImageDisplay);
