import { Outlet } from "react-router-dom";
import "./DetailLayout.css";
import SideBar from "../../components/SideBar/SideBar";
import { detailTabs } from "@/common/constants/user-page.constants";

const DetailLayout: React.FC = () => {
  return (
    <div className="layout flex h-screen bg-no-repeat">
      <SideBar tabs={detailTabs} mode="detail"  />
      <main className="overflow-auto h-full w-full">
        <Outlet />
      </main>
    </div>
  );
};

export default DetailLayout;
