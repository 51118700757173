import { TOKEN_DECIMALS } from '@/common/constants/common.constants';
import { handleException } from '@/common/exceptions/exception-handling';
import * as toast from '@/common/helpers/toast';
import AppFormField from '@/components/atom/AppFormField';
import AppButton from '@/components/atom/Button/Button';
import TextField from '@/components/atom/TextField/TextField';
import Heading1 from '@/components/common/Heading1';
import { useIssueAp } from '@/contexts/IssueApContext';
import { useLoading } from '@/hooks/useLoading';
import { PaymentApi } from '@/module/payment/api';
import { ISSUE_AP_SCHEMA } from '@/module/payment/issueAp.validator';
import { IssueApPayload, TIssueApSchema } from '@/module/payment/type.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const LIST_AP_AMOUNT_DEFAULT = [10000, 20000, 50000, 100000, 200000, 5000000, 1000000];

export const IssueApForm = () => {
  const { checkedUserId } = useIssueAp();
  const { openLoading, closeLoading } = useLoading();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<TIssueApSchema>({
    resolver: yupResolver(ISSUE_AP_SCHEMA),
  });

  const handleBack = () => {
    navigate(`/issue-ap`);
  };

  const handleAddAPAmount = (value: number) => {
    const currentApAmount = getValues('apAmount') && !isNaN(getValues('apAmount')) ? getValues('apAmount') : 0;
    const newApAmount = currentApAmount + value;
    setValue('apAmount', newApAmount);
    trigger('apAmount');
  };

  const handleApAmountChange = (value: string) => {
    const numericValue = Number(Number(value).toFixed(TOKEN_DECIMALS.AP));
    setValue('apAmount', numericValue || 0);
    trigger('apAmount');
  };

  const handleIssueAp = async (fields: TIssueApSchema) => {
    if (!checkedUserId) return;
    try {
      openLoading();
      const payload: IssueApPayload = {
        apAmount: String(fields.apAmount),
        userId: checkedUserId,
      };
      await PaymentApi.issueAP(payload);
      toast.showSuccess('Success');
      navigate('/issue-ap');
    } catch (err) {
      const errorResult = handleException(err);
      toast.showError(errorResult.errorMessage ?? errorResult.errorMessages);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    if (!checkedUserId) {
      return handleBack();
    }
  }, [checkedUserId]);

  return (
    <div className="space-y-[90px] px-20 py-16">
      <Heading1 text="AP発行" backAction={handleBack} />
      <form className="flex flex-col items-center space-y-[90px]" onSubmit={handleSubmit(handleIssueAp)}>
        <div className="flex w-full flex-col gap-5">
          <AppFormField textLabel="発行AP量" required={true} errorText={errors.apAmount?.message}>
            <Controller
              name="apAmount"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  placeholder="10,000,000AP"
                  error={errors.apAmount}
                  className="[&>input]:px-5 [&>input]:text-right [&>input]:text-base"
                  {...field}
                  value={field.value || ''}
                  onChange={(e) => handleApAmountChange(e.target.value)}
                />
              )}
            />
          </AppFormField>
          <div className="flex flex-wrap gap-3">
            {LIST_AP_AMOUNT_DEFAULT.map((apAmount, index) => (
              <AppButton
                key={index}
                type="button"
                custome="!px-3"
                variant="outline-blue"
                text={`+${apAmount}`}
                onClick={() => handleAddAPAmount(apAmount)}
              />
            ))}
          </div>
        </div>
        <AppButton text="送付" size="lg" />
      </form>
    </div>
  );
};
