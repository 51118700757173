import { IColumns } from "./type.interface";

export enum ColumnInterviewsId {
    NAME = "name",
    EMAIL = "email",
    APPLICATION_DATE = "application_date",
    PASS_FAIL = "update_date",
    PAYMENT = "payment",
    SUPPORTER_NAME = "supporter_name",
  }
  
export const INTERVIEWS_COLUMNS: IColumns[] = [
    {
      id: ColumnInterviewsId.NAME,
      label: "リターン名",
      className: "text-left p-2.5 w-[26%]",
    },
    {
      id: ColumnInterviewsId.EMAIL,
      label: "メールアドレス",
      className: "text-left p-2.5 w-[26%]",
    },
    {
      id: ColumnInterviewsId.SUPPORTER_NAME,
      label: "応援者名",
      className: "text-left p-2.5 w-[15%]",
    },
    {
      id: ColumnInterviewsId.APPLICATION_DATE,
      label: "申請⽇",
      className: "text-left p-2.5 w-[23%]",
    },
    {
      id: ColumnInterviewsId.PASS_FAIL,
      label: "合否",
      className: "text-left p-2.5 w-[15%]",
    },
    {
      id: ColumnInterviewsId.PAYMENT,
      label: "⽀払",
      className: "text-left p-2.5 w-[15%]",
    },
  ];

  export enum EInterviewStatus {
    PENDING = 1,
    APPROVED = 2,
    DENIED = 3,
    AWAITING_PAYMENT = 4,
    PAID = 5,
  }

  export const INTERVIEW_STATUS = {
    [EInterviewStatus.PENDING]: "判定",
    [EInterviewStatus.APPROVED]: "合格",
    [EInterviewStatus.PAID]: "合格",
    [EInterviewStatus.DENIED]: "不合格"
  }

  export const PAYMENT_STATUS = {
    [EInterviewStatus.DENIED]: "なし",
    [EInterviewStatus.PAID]: "支払済",
    [EInterviewStatus.APPROVED]: "未払い",
    [EInterviewStatus.PENDING]: "合否待ち"
  }

  export enum EInterviewLabelDetail {
    FIRST_NAME = "姓",
    LAST_NAME = "名",
    DOB = "生年月日",
    PHONE = "電話番号",
    EMAIL = "メールアドレス",
    SEX = "性別",
    SEFT_INTRO = "自己PR"
  }
  export enum ESexType {
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER"
  }

  export const SEX_CODE = {
    [ESexType.MALE]: "男性",
    [ESexType.FEMALE]: "女性",
    [ESexType.OTHER]: "その他"
  }