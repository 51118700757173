import { ERROR_MESSAGE, REGEX_ONLY_NUMBER, REGEX_PASSWORD } from "@/common/constants/common.constants";
import * as yup from "yup";

export const USERS_SCHEMA = yup.object().shape({
    firstName: yup
      .string()
      .required(ERROR_MESSAGE.required)
      .max(255, ERROR_MESSAGE.wrongFormat),
    lastName: yup
      .string()
      .required(ERROR_MESSAGE.required)
      .max(255, ERROR_MESSAGE.wrongFormat),
    firstNameKana: yup
      .string()
      .required(ERROR_MESSAGE.required)
      .max(255, ERROR_MESSAGE.wrongFormat),
    lastNameKana: yup
      .string()
      .required(ERROR_MESSAGE.required)
      .max(255, ERROR_MESSAGE.wrongFormat),
    email: yup
      .string()
      .required(ERROR_MESSAGE.required)
      .email(ERROR_MESSAGE.wrongFormat),
    tel: yup
      .string()
      .required(ERROR_MESSAGE.required)
      .min(10, ERROR_MESSAGE.wrongFormat)
      .max(12, ERROR_MESSAGE.wrongFormat)
      .matches(REGEX_ONLY_NUMBER, ERROR_MESSAGE.wrongFormat),
    password: yup
      .string()
      .required(ERROR_MESSAGE.required)
      .matches(REGEX_PASSWORD, ERROR_MESSAGE.password),
    rePassword: yup
      .string()
      .oneOf([yup.ref("password")], ERROR_MESSAGE.rePassword)
      .required(ERROR_MESSAGE.rePassword),
  });