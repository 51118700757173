import { IResponseResult } from '@/interfaces/common.interface';
import axios from '../../api/axios';
import { IInterviewsResponse } from './response/interviews.interface';
import { IInterviewsParams } from './request/interviews.interface';
import { EInterviewStatus } from './constants';
import { downloadCsv } from '@/common/helpers/file-download';


export class InterviewsApi {
    static getAllInterviews(projectID: string, params?: IInterviewsParams): Promise<IResponseResult<IInterviewsResponse[]>> {
        return axios.get(`support-interview/projects/${projectID}`, {params}).then((res) => res.data);
    }
    static approveInterview(interviewId: string) {
        return axios.put(`support-interview/${interviewId}`, {status: EInterviewStatus.APPROVED});
    }

    static cancelInterview(interviewId: string) {
        return axios.put(`support-interview/${interviewId}`, {status: EInterviewStatus.DENIED});
    }

    static paid(interviewId: string) {
        return axios.put(`support-interview/${interviewId}`, {status: EInterviewStatus.PAID});
    }

    static async exportCsv(projectID: string): Promise<void> {
        const response: any = await axios.post(
            `support-interview/projects/${projectID}/export-all`,
        );
        await downloadCsv(response.data, "面談一覧");
    }
}