import Toast from '@/components/Toast';
import { toast } from 'react-toastify';

export const showError = (content: string | '') => {
  if(!content) return;
  return toast(<Toast backgroundColor={'bg-red-450'} content={content} />);
};

export const showSuccess = (content: string) => {
  return toast(<Toast backgroundColor={'bg-blue-450'} content={content} />);
};
