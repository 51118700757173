import React from "react";
import "./AuthLayout.css";
import { Flex } from "@radix-ui/themes";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const LoginLayout: React.FC = () => {
  return (
    <>
      <Flex
        className="login-container h-screen"
        justify={"center"}
        align={"center"}
        position={"relative"}
      >
        <img
          className="w-full h-screen object-cover"
          src="/assets/images/login-background.svg"
          alt="34124"
        />
        <Flex
          className="login-main-content absolute px-4"
          direction={"column"}
          gap={"18px"}
        >
          <div>
            <img
              className="mx-auto"
              src="/assets/images/arigato-max-logo.svg"
              alt="img"
              width={298}
              height={312}
            />
            <p className="text-primary-500 font-medium text-4xl text-center tracking-widest">
              ArigatoMax
            </p>
          </div>
          <Outlet />
        </Flex>
      </Flex>
    </>
  );
};

export default LoginLayout;
