import { BE_MESSAGE } from "@/common/constants/common.constants";
import Heading1 from "@/components/common/Heading1";
import { AdminUsersApi } from "@/module/adminUsers/api";
import { RegisterFormInputs } from "@/module/adminUsers/type.interface";
import { USERS_SCHEMA } from "@/module/adminUsers/validations/adminUsers.validations";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import RegeisterForm from "../components/RegeisterForm";
import { useLoading } from "@/hooks/useLoading";
import { handleException } from "@/common/exceptions/exception-handling";
import * as toast from '@/common/helpers/toast';

interface EditPageProps {}

const EditPage: React.FC<EditPageProps> = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RegisterFormInputs>({
    resolver: yupResolver(USERS_SCHEMA),
  });
  const navigate = useNavigate();
  const { adminUserId } = useParams() || "";
  const [errorMsg, setErrorMsg] = useState("");
  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    if (adminUserId) {
      fetchDataUser(adminUserId);
    }
  }, []);

  const fetchDataUser = async (adminUserId: string) => {
    try {
      openLoading();
      const response = await AdminUsersApi.getUser(adminUserId);
      const { data } = response;
      if (data) {
        const defaultValues = {
          firstName: data.firstName,
          lastName: data.lastName,
          firstNameKana: data.firstNameKana,
          lastNameKana: data.lastNameKana,
          email: data.email,
          tel: data.tel,
        };
        reset({ ...defaultValues });
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const onSubmit: SubmitHandler<RegisterFormInputs> = async (
    data: RegisterFormInputs
  ) => {
    try {
      const {
        firstName,
        lastName,
        firstNameKana,
        lastNameKana,
        email,
        tel,
        password,
      } = data;
      const body = {
        lastName,
        firstName,
        lastNameKana,
        firstNameKana,
        password,
        email,
        tel,
      };
      const response = await AdminUsersApi.editUser(adminUserId || "", body);
      if (response) {
        navigate(`/admin-users`);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && setErrorMsg(errorMessage);
    }
  };

  const backAction = () => {
    navigate(`/admin-users`);
  };

  return (
    <div className="px-20 py-16">
      <Heading1 text="管理者編集" backAction={backAction} className={"mb-10"} />
      <RegeisterForm
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        errorMsg={errorMsg}
        textSubmit="保存"
      />
    </div>
  );
};

export default EditPage;
