import React, { useEffect, useState } from "react";
import AppButton from "@/components/atom/Button/Button";
import Heading1 from "@/components/common/Heading1";
import { useNavigate } from "react-router-dom";
import { useLoading } from "@/hooks/useLoading";
import * as toast from "@/common/helpers/toast";
import TableNotifications from "./components/TableNotifications";
import { NOTIFICATIONS_COLUMNS } from "@/module/notifications/constants";
import { NotificationsApi } from "@/module/notifications/api";
import { INotificationsResponse } from "@/module/notifications/response/notifications.interface";
import { handleException } from "@/common/exceptions/exception-handling";
interface NotificationsPageProps {}

const NotificationsPage: React.FC<NotificationsPageProps> = () => {
  const navigate = useNavigate();
  const [listNotifications, setListNotifications] = useState<INotificationsResponse[]>([]);
  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    fetchAllNotifications();
  }, []);

  const fetchAllNotifications = async () => {
    try {
      openLoading();
      const response = await NotificationsApi.getAllNotifications();
      if (response?.data) {
        setListNotifications(response?.data);
      }
    } catch (error: any) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };
  const backAction = () => {
    navigate(`/home`);
  };

  const editAction = (notiId: string) => {
    navigate(`/notifications/edit/${notiId || ""}`);
  };

  const deleteAction = async (notiId: string) => {
    try {
      openLoading();
      await NotificationsApi.deleteNotification(notiId);
      fetchAllNotifications();
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const createAction = () => {
    navigate(`/notifications/create`);
  };

  return (
    <div className="px-20 py-16">
      <Heading1 text="通知一覧画面" backAction={backAction} />
      <div className="mt-10 mb-14">
        <div className="flex justify-end">
          <AppButton text="新規登録" size="lg" onClick={createAction} />
        </div>
      </div>
      <div className="">
        <TableNotifications
          columns={NOTIFICATIONS_COLUMNS}
          editAction={editAction}
          deleteAction={deleteAction}
          data={listNotifications}
        />
      </div>
    </div>
  );
};

export default NotificationsPage;
