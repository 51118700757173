import * as toast from '@/common/helpers/toast';
import { BE_MESSAGE } from '../constants/common.constants';

type ErrorResult = {
    hasLogicError: boolean;
    // errorCode: keyof typeof BE_MESSAGE | null;
    errorMessage: string;
    errorMessages?: string[] | null;
}

export const handleException = (error: any): ErrorResult => {
    const message = error?.response?.data?.message;
    if (error?.response?.status === 500) {
        toast.showError(message || BE_MESSAGE.SYSTEM_ERROR);
        return {
            hasLogicError: false,
            // errorCode: null,
            errorMessage: '',
        }
    }

    return {
        hasLogicError: true,
        // errorCode: error?.response?.data?.message_code || 'AMCMM0001',
        errorMessage: typeof message === 'string' ? message : '',
        errorMessages: Array.isArray(message) ? message as string[] : null,
    }
}