import { TOKEN_DECIMALS } from '@/common/constants/common.constants';
import AppFormField from '@/components/atom/AppFormField';
import AppButton from '@/components/atom/Button/Button';
import AppImageUploading from '@/components/atom/ImageUploading/ImageUploading';
import Switcher from '@/components/atom/Switcher';
import AppTextArea from '@/components/atom/TextArea/TextArea';
import TextField from '@/components/atom/TextField/TextField';
import { IStepThreeFormItem } from '@/module/projectsList/project.interface';
import { STEP_THREE_SCHEMA } from '@/module/projectsList/validations/stepThree.validation';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';
import { v4 as uuidv4 } from 'uuid';
import { STEP3_SCREEN } from '../StepThree';

interface IStepThreeFormData {
  id?: string;
  returnName: string;
  thumbnailUrl: any;
  isNft: boolean;
  isQuantitySelectable: boolean;
  isInterviewRequired: boolean;
  apAmount: number;
  quantity: number;
  content: string;
  isPublic?: boolean;
  createdAt?: string;
}

interface IFormScreenProps {
  onSubmitForm: (screen: STEP3_SCREEN, formDatas?: IStepThreeFormItem) => void;
  defaultData?: IStepThreeFormItem;
  status?: Boolean;
}

const FormScreen: React.FC<IFormScreenProps> = (props) => {
  const { onSubmitForm, defaultData, status } = props;
  const [thumbnailUrl, setThumbnailUrl] = React.useState<ImageListType[]>([]);

  const disabled = status && Boolean(defaultData);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IStepThreeFormData>({
    defaultValues: {
      returnName: defaultData?.returnName,
      thumbnailUrl: defaultData?.thumbnailUrl,
      isNft: defaultData?.isNft ?? false,
      isQuantitySelectable: defaultData?.isQuantitySelectable ?? false,
      isInterviewRequired: defaultData?.isInterviewRequired ?? false,
      apAmount: Number(defaultData?.apAmount),
      quantity: defaultData?.quantity,
      content: defaultData?.content,
      isPublic: defaultData?.isPublic,
      createdAt: defaultData?.createdAt,
    },
    resolver: yupResolver(STEP_THREE_SCHEMA),
  });

  const handleSwitchScreen = useCallback(() => {
    onSubmitForm(STEP3_SCREEN.list);
  }, [onSubmitForm]);

  const onSubmit: SubmitHandler<IStepThreeFormData> = useCallback(
    (data: IStepThreeFormData) => {
      let mappingData: IStepThreeFormItem = {
        id: defaultData?.id || uuidv4(),
        returnName: data.returnName,
        thumbnailUrl: (thumbnailUrl as ImageListType)[0]?.file || (defaultData?.thumbnailUrl as string | File),
        isNft: data.isNft,
        isQuantitySelectable: data.isQuantitySelectable,
        isInterviewRequired: data.isInterviewRequired,
        apAmount: String(data.apAmount),
        quantity: data.quantity,
        content: data.content,
        isPublic: data?.isPublic,
        createdAt: data?.createdAt,
      };
      onSubmitForm(STEP3_SCREEN.list, mappingData);
    },
    [defaultData?.id, defaultData?.thumbnailUrl, onSubmitForm, thumbnailUrl],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="flex flex-col items-start space-y-14 px-3">
        <div className="mb-2 flex w-full flex-col gap-6">
          <AppFormField textLabel="リターン名" required={true} errorText={errors.returnName?.message}>
            <Controller
              control={control}
              name="returnName"
              render={({ field }) => (
                <TextField
                  disabled={disabled && defaultData?.isNft}
                  error={errors.returnName}
                  placeholder="映画鑑賞チケット"
                  {...field}
                ></TextField>
              )}
            />
          </AppFormField>

          <AppFormField
            textLabel="サムネイル画像"
            required={true}
            hint={errors.thumbnailUrl ? '' : '3:2(横1200px 縦800px 程度)'}
            errorText={errors.thumbnailUrl?.message as string}
          >
            <Controller
              name="thumbnailUrl"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AppImageUploading
                  onChange={(image) => {
                    onChange(image);
                    setThumbnailUrl(image as never[]);
                  }}
                  value={thumbnailUrl}
                  defaultValue={value}
                  disabled={disabled && defaultData?.isNft}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="リターンをNFTとして送付" required={true} errorText={errors.isNft?.message as string}>
            <div className="flex items-center gap-1">
              <Controller
                name="isNft"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switcher onCheckedChange={onChange} checked={value} disabled={disabled} />
                )}
              />
              {disabled && <p className="text-[11px] font-medium leading-4 text-secondary-500">変更不可</p>}
            </div>
          </AppFormField>

          <AppFormField
            textLabel="複数個選択可"
            required={true}
            errorText={errors.isQuantitySelectable?.message as string}
          >
            <Controller
              name="isQuantitySelectable"
              control={control}
              render={({ field: { onChange, value } }) => <Switcher onCheckedChange={onChange} checked={value} />}
            />
          </AppFormField>

          <AppFormField
            textLabel="面談の有無"
            required={true}
            errorText={errors.isInterviewRequired?.message as string}
          >
            <div className="flex items-center gap-1">
              <Controller
                name="isInterviewRequired"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switcher onCheckedChange={onChange} checked={value} disabled={disabled} />
                )}
              />
              {disabled && <p className="text-[11px] font-medium leading-4 text-secondary-500">変更不可</p>}
            </div>
          </AppFormField>

          <AppFormField textLabel="金額AP" required={true} errorText={errors.apAmount?.message}>
            <Controller
              control={control}
              name="apAmount"
              render={({ field }) => (
                <TextField
                  type="number"
                  error={errors.apAmount}
                  placeholder="金額APを入力する"
                  {...field}
                  value={field.value || ''}
                  onChange={(e) => {
                    const newValue = Number(Number(e.target.value).toFixed(TOKEN_DECIMALS.AP));
                    field.onChange(newValue || '');
                  }}
                ></TextField>
              )}
            />
          </AppFormField>

          <AppFormField textLabel="リターン上限数" required={true} errorText={errors.quantity?.message}>
            <Controller
              control={control}
              name="quantity"
              render={({ field }) => (
                <TextField
                  type="number"
                  error={errors.quantity}
                  placeholder="リターン上限数を入力する"
                  {...field}
                ></TextField>
              )}
            />
          </AppFormField>

          <AppFormField textLabel="リターン内容" required={true} errorText={errors.content?.message}>
            <Controller
              control={control}
              name="content"
              render={({ field }) => (
                <AppTextArea
                  custome="h-[125px]"
                  placeholder="リターン内容を入力する"
                  error={errors.content}
                  {...field}
                />
              )}
            />
          </AppFormField>
        </div>
      </div>
      <div className="mt-[6rem] flex gap-2">
        <AppButton variant="outline" text="キャンセル" size="lg" onClick={handleSwitchScreen} />
        <AppButton text="保存" size="lg" type="submit" />
      </div>
    </form>
  );
};

export default FormScreen;
