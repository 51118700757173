import axios from '@/api/axios';
import { IResponseResult } from '@/interfaces/common.interface';
import { IUsersBody } from '../users/request/users.interface';
import { IUsersResponse } from '../users/response/users.interface';
import { CreateAffiliatePayload, CreateAffiliateResponse } from './type.interface';

export class AffiliateApi {
  public static async getUsers(params?: IUsersBody) {
    const response = await axios.get<IResponseResult<IUsersResponse[]>>('/affiliate/users', { params });
    return response.data;
  }

  public static async create(payload: CreateAffiliatePayload) {
    const response = await axios.post<IResponseResult<CreateAffiliateResponse>>('/affiliate', payload);
    return response.data;
  }
}
