import React from "react";
import ConfirmPopup from "@/components/modals/ConfirmPopup";

interface IPublishedPopup {
  projectID: string;
  openPopup: boolean;
  handleConfirm: () => void;
}

const PublishedPopup = ({
  projectID,
  openPopup,
  handleConfirm,
}: IPublishedPopup) => {
  const arigatoMaxUrl = `${process.env.REACT_APP_ARIGATO_MAX_URL}/project-detail/${projectID}`;
  const popupConfig = {
    title: "プロジェクトが公開されました🎉",
    confirmText: "閉じる",
    children: (
      <div className="font-medium">
        <p className="text-secondary-500 text-base mt-0 mb-1">公開URL</p>
        <a
          href={arigatoMaxUrl}
          className="text-base leading-6 text-link"
          target="blank"
        >
          {arigatoMaxUrl}
          <img
            src="/assets/images/go-to-the-link.svg"
            alt=""
            className="ml-1 inline-block"
          />
        </a>
      </div>
    ),
    onConfirm: () => handleConfirm(),
    open: openPopup,
  };

  return (
    <>
      <ConfirmPopup popupConfig={popupConfig} className="w-[343px] !p-4" />
    </>
  );
};

export default PublishedPopup;
