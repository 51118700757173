import Heading1 from "@/components/common/Heading1";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useLoading } from "@/hooks/useLoading";
import NotificationForm from "../components/NotificationForm";
import { NotificationFormInputs } from "@/module/notifications/type.interface";
import { NOTIFICATION_SCHEMA } from "@/module/notifications/validations/notifications.validations";
import { ENotificationTarget } from "@/module/notifications/constants";
import { NotificationsApi } from "@/module/notifications/api";
import {
  BE_MESSAGE,
  FULL_DATE_TIME_FORMAT,
} from "@/common/constants/common.constants";
import { format } from "date-fns";
import { handleException } from "@/common/exceptions/exception-handling";
import * as toast from "@/common/helpers/toast";

interface EditNotificationPageProps {}

const EditNotificationPage: React.FC<EditNotificationPageProps> = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { errors },
  } = useForm<NotificationFormInputs>({
    resolver: yupResolver(NOTIFICATION_SCHEMA),
  });
  const navigate = useNavigate();
  const { notiId } = useParams() || "";
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultValue, setDefaultValue] =
    useState<NotificationFormInputs | null>(null);
  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    if (notiId) {
      fetchDataNoti(notiId);
    }
  }, []);

  const fetchDataNoti = async (notiId: string) => {
    try {
      openLoading();
      const response = await NotificationsApi.getNotification(notiId);
      const { data } = response;
      if (data) {
        const { startDate, endDate, title, category, sentence } = data;
        const defaultValues: NotificationFormInputs = {
          title,
          category,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          content: sentence,
        };
        setDefaultValue(defaultValues);
        reset({ ...defaultValues });
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const onSubmit: SubmitHandler<NotificationFormInputs> = async (
    data: NotificationFormInputs
  ) => {
    try {
      const { category, title, content, startDate, endDate } = data;
      const body = {
        title,
        category,
        sentence: content,
        startDate: startDate.toISOString() || "",
        endDate: endDate.toISOString() || "",
        target: ENotificationTarget.USER,
      };
      const response = await NotificationsApi.editNotification(
        notiId || "",
        body
      );
      if (response) {
        navigate(`/notifications`);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      if (errorMessage) {
        setErrorMsg(errorMessage);
      }
    }
  };

  const backAction = () => {
    navigate(`/notifications`);
  };

  return (
    <div className="px-20 py-16">
      <Heading1
        text="通知編集画面"
        backAction={backAction}
        className={"mb-10"}
      />
      <NotificationForm
        register={register}
        control={control}
        trigger={trigger}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        errorMsg={errorMsg}
        textSubmit="保存"
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default EditNotificationPage;
