import AppButton from "@/components/atom/Button/Button";
import Heading1 from "@/components/common/Heading1";
import React, {  } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

interface PreviewPageProps {}

const PreviewPage: React.FC<PreviewPageProps> = () => {
  const navigate = useNavigate();

  const { projectId } = useParams() || "";
  let [searchParams, setSearchParams] = useSearchParams();

  const navFrom = searchParams.get("from");
  const arigatoMaxUrl = `${process.env.REACT_APP_ARIGATO_MAX_URL}/project-detail/${projectId}?isPreview=true`;

  const handleBack = () => {
    if (navFrom === "dashboard")
      return navigate(`/projects/${projectId}/dashboard`);
    navigate(`/home`);
  };

  return (
    <div className="pb-16">
      <Heading1
        text="プレビュー画面"
        backAction={handleBack}
        className="px-20 py-16"
      />
      <div className="flex gap-8 flex-wrap h-[calc(100vh-14rem)] px-10 pb-8">
        <iframe
          src={arigatoMaxUrl}
          height={"100%"}
          width={"100%"}
        />
      </div>
      <div className="flex items-center w-full">
        <AppButton text="戻る" size="lg" onClick={handleBack} custome="m-auto" />
      </div>
    </div>
  );
};

export default PreviewPage;
