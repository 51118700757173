import React, { useCallback, useEffect } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormWatch,
} from "react-hook-form";
import TextEditor from "@/components/TextEditor";
import AppButton from "@/components/atom/Button/Button";
import AppFormField from "@/components/atom/AppFormField";
import {
  IProject,
  IStepTwoForm,
} from "@/module/projectsList/project.interface";
import { EStatusProject } from "@/module/projectsList/constants";

export interface IStepTwoFormData {
  description: string;
}

interface StepTwoProps {
  onNextStep: (step: number, data: IStepTwoForm) => void;
  onSaveDraft: (data: IStepTwoForm) => void;
  onFormChange: (data: IStepTwoForm) => void;
  errorMsg?: string;
  errors: FieldErrors<IProject>;
  watch: UseFormWatch<IProject>;
  control: Control<IProject>;
  getValues: UseFormGetValues<IProject>;
  status?: EStatusProject;
}

const StepTwo: React.FC<StepTwoProps> = ({
  onNextStep,
  onSaveDraft,
  onFormChange,
  errors,
  watch,
  control,
  getValues,
  status,
}) => {
  const onSubmit = useCallback(() => {
    const _data: IStepTwoForm = {
      content: getValues("description"),
    };
    onNextStep(3, _data);
  }, [onNextStep]);

  const handleSaveDraft = useCallback(() => {
    const _data: IStepTwoForm = {
      content: getValues("description"),
    };
    onSaveDraft(_data);
  }, [getValues, onSaveDraft]);

  const onClickSaveDraft = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    handleSaveDraft();
  };

  const watchedValues = watch();

  useEffect(() => {
    const data: IStepTwoForm = {
      content: watchedValues.description,
    };

    onFormChange(data);
  }, [onFormChange, watchedValues]);

  return (
    <div className="px-20 py-16">
      <form>
        <AppFormField
          textLabel="本文 (30文字以上、1000文字以下)"
          required={true}
          errorText={errors.description?.message}
        >
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextEditor
                onEditorChange={onChange}
                editorClassName="h-[600px] border-rounded"
                value={value}
                placeholder="本文を入力する"
              />
            )}
          />
        </AppFormField>
        <div className="mt-20 flex gap-3">
          {(status === EStatusProject.DRAFT || status === undefined) && (
            <AppButton
              text="下書きを保存"
              size="lg"
              variant="outline"
              onClick={(event) => onClickSaveDraft(event)}
            />
          )}
          <AppButton text="次へ" size="lg" onClick={onSubmit} />
        </div>
      </form>
    </div>
  );
};

export default StepTwo;
