import { IResponseResult } from "@/interfaces/common.interface";
import axios from "../../api/axios";
import { ISupporterResponse } from "./response/supportersList.interface";
import { ISupporterParams } from "./request/supportersList.interface";
import { downloadCsv } from "@/common/helpers/file-download";

export class SupportersApi {
  static getAllSupporters(
    projectID: string,
    params?: ISupporterParams,
  ): Promise<IResponseResult<ISupporterResponse[]>> {
    return axios
      .get(`projects/${projectID}/supports`, {
        params,
      })
      .then((res) => res.data);
  }

  static async exportCsv(projectID: string): Promise<void> {
    const response: any = await axios.post(
      `projects/${projectID}/supports/export-all`,
    );
    await downloadCsv(response.data, "支援者一覧");
  }
}
