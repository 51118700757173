import { format } from "date-fns";
import { FULL_DATE_DASH_FORMAT, FULL_TIME_DASH_FORMAT } from "../constants/common.constants";

export const downloadCsv = async (data: any, fileNamePrefix: string): Promise<void> => {
  const now = new Date();
  const date = format(now, FULL_DATE_DASH_FORMAT);
  const time = format(now, FULL_TIME_DASH_FORMAT);

  const url = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
  const link = document.createElement("a");
  const fileName = `${date}_${time}_${fileNamePrefix}.csv`;
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  // Clean up
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
};
