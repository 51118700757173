import React from "react";

interface Header1PropsProps {
  text: string;
  backAction?: () => void;
  className?: string;
}

const Heading1: React.FC<Header1PropsProps> = ({
  text,
  backAction,
  className,
}) => {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      {backAction && (
        <img
          className="w-8 h-8 cursor-pointer"
          src="/assets/images/back-icon.svg"
          onClick={backAction}
        ></img>
      )}
      <h1 className="text-4xl font-bold">{text}</h1>
    </div>
  );
};

export default Heading1;
