import { handleException } from '@/common/exceptions/exception-handling';
import * as toast from '@/common/helpers/toast';
import AppButton from '@/components/atom/Button/Button';
import TextField from '@/components/atom/TextField/TextField';
import Heading1 from '@/components/common/Heading1';
import { useIssueAp } from '@/contexts/IssueApContext';
import { useLoading } from '@/hooks/useLoading';
import { ISSUE_AP_USERS_COLUMNS } from '@/module/payment/constants';
import { UsersApi } from '@/module/users/api';
import { IUsersBody } from '@/module/users/request/users.interface';
import { IUsersResponse } from '@/module/users/response/users.interface';
import { TextField as Text } from '@radix-ui/themes';
import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableUsers } from './Components';

export const IssueApUserSelectionPage = () => {
  const { openLoading, closeLoading } = useLoading();
  const { checkedUserId, setCheckedUserId } = useIssueAp();
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState<IUsersResponse[]>([]);
  const search = searchParams.get('search') || '';
  const [value, setValue] = useState(search);
  const navigate = useNavigate();
  const debouncedSearch = useRef(
    debounce(async (params) => {
      await fetchAllUsers({ search: params });
    }, 300),
  ).current;

  const handleBack = () => {
    navigate(`/`);
  };

  const fetchAllUsers = async (params?: IUsersBody) => {
    try {
      openLoading();
      const response = await UsersApi.getAllUsers(params);
      setUsers(response.data);
    } catch (err) {
      const errorResult = handleException(err);
      toast.showError(errorResult.errorMessage ?? errorResult.errorMessages);
    } finally {
      closeLoading();
    }
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
    setValue(event.target.value);
    navigate(`/issue-ap?search=${event.target.value}`);
  };

  const handleClick = () => {
    if (!checkedUserId) {
      toast.showError('Please choose a user');
    } else {
      navigate(`/issue-ap/form`);
    }
  };

  useEffect(() => {
    fetchAllUsers({ search });
    setCheckedUserId(null);
  }, []);

  return (
    <div className="px-20 py-16 space-y-11">
      <Heading1 text="AP発行 - ユーザー選択" backAction={handleBack} />
      <TextField placeholder="メールアドレス検索" value={value} onChange={handleSearch}>
        <Text.Slot></Text.Slot>
        <Text.Slot>
          <img src="/assets/images/search-icon.svg" alt="" />
        </Text.Slot>
      </TextField>
      <TableUsers columns={ISSUE_AP_USERS_COLUMNS} data={users} />
      <div className="flex justify-center">
        <AppButton text="次へ" size="lg" onClick={handleClick} disabled={!checkedUserId} />
      </div>
    </div>
  );
};
