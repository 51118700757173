import React from 'react';

interface AppButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  variant?: string;
  size?: string;
  custome?: string;
  children?: React.ReactNode;
}

const AppButton = React.forwardRef<HTMLButtonElement, AppButtonProps>((props, ref) => {
  const styleVariantButton = (variant: string) => {
    switch (variant) {
      case 'outline':
        return 'bg-transparent font-bold border text-primary-500 border-primary-500 rounded-md md:hover:text-primary-600 lg:hover:bg-grey-100 disabled:!text-primary-500';
      case 'outline-blue':
        return 'bg-transparent font-bold border text-primary-blue-500 border-primary-blue-500 rounded-md md:hover:text-primary-blue-500 lg:hover:bg-grey-100';
      case 'default':
        return 'bg-primary-500 text-base font-bold text-white rounded-md lg:hover:bg-primary-250 disabled:bg-secondary disabled:hover:bg-secondary';
      default:
        return 'bg-primary-500 text-base font-bold text-white rounded-md lg:hover:bg-primary-250 disabled:bg-secondary disabled:hover:bg-secondary';
    }
  };

  const styleSizeButton = (size: string) => {
    switch (size) {
      case 'sm':
        return 'py-1 text-[11px]';
      case 's-md':
        return 'py-1 px-4';
      case 'md':
        return 'py-1.5 px-8';
      case 'lg':
        return 'py-3 min-w-[246px]';
      default:
        return 'py-1.5 px-8';
    }
  };

  return (
    <button
      className={`${props.custome || ''} ${styleVariantButton(props.variant || '')} ${styleSizeButton(props.size || '')}`}
      {...props}
      ref={ref}
    >
      {props.text && props.text}
      {props.children && props.children}
    </button>
  );
});

export default AppButton;
