interface IRowData {
  label: string;
  data: string | number;
}

const RowData = ({ label, data }: IRowData) => {
  return (
    <div className="flex items-center flex-1 w-full">
      <div className="text-base cursor-default text-neutral-850 font-bold flex-1">
        {label}
      </div>
      <div className="text-xl cursor-default text-secondary-500 font-bold leading-6 flex-1">
        {data}
      </div>
    </div>
  );
};

export default RowData;
