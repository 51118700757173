import axios from '@/api/axios';
import { RegisterFormBody } from './request/adminUsers.interface';
import { IAdminUsersResponse } from './response/adminUsers.interface';
import { IResponseResult } from '@/interfaces/common.interface';

export class AdminUsersApi {
    static register(body: RegisterFormBody) {
        return axios.post('register', body);
    }

    static getAllUsers(): Promise<IResponseResult<IAdminUsersResponse[]>> {
        return axios.get('admins').then((res) => res.data);
    }

    static getUser(adminUserId: string): Promise<IResponseResult<IAdminUsersResponse>> {
        return axios.get(`admins/${adminUserId}`).then((res) => res.data);
    }

    static editUser(adminUserId: string, body: RegisterFormBody) {
        return axios.put(`admins/${adminUserId}`, body);
    }

    static deleteUser(adminUserId: string) {
        return axios.delete(`admins/${adminUserId}`);
    }
}