import { ERROR_MESSAGE } from '@/common/constants/common.constants';
import * as yup from 'yup';

export const STEP_THREE_SCHEMA = yup.object().shape({
  returnName: yup.string().required(ERROR_MESSAGE.required),
  thumbnailUrl: yup.mixed().required(ERROR_MESSAGE.required),
  isNft: yup.boolean().required(ERROR_MESSAGE.required),
  isQuantitySelectable: yup.boolean().required(ERROR_MESSAGE.required),
  isInterviewRequired: yup.boolean().required(ERROR_MESSAGE.required),
  apAmount: yup
    .number()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .min(1, ERROR_MESSAGE.wrongFormat)
    .required(ERROR_MESSAGE.required),
  quantity: yup
    .number()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .integer(ERROR_MESSAGE.wrongFormat)
    .min(1, ERROR_MESSAGE.wrongFormat)
    .required(ERROR_MESSAGE.required),
  content: yup.string().required(ERROR_MESSAGE.required),
});
