import React, { useCallback, useMemo } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "../MultiStepProgressBar/MultiStepProgressBar.css";
const steps = ["概要", "本文", "リターン", "表記"];

interface IStepBarProps {
  currentStep?: number;
  completedSteps?: number[];
  onChangeStep?: (step: number) => void;
}

const StepBar: React.FC<IStepBarProps> = (props) => {
  const { currentStep = 1, completedSteps = [], onChangeStep } = props;
  const handleChangeStep = useCallback(
    (step: number) => {
      if (step !== currentStep) {
        onChangeStep?.(step);
      }
    },
    [currentStep, onChangeStep]
  );

  const stepColor = useCallback(
    (index: number) => {
      let classes = "";
      if (currentStep !== index + 1) {
        classes += "cursor-pointer ";
        if (!completedSteps.includes(index + 1))
          classes += "bg-primary-900 text-primary-250 ";
        else classes += "bg-primary-500 ";
      } else {
        if (!completedSteps.includes(index + 1))
          classes += "bg-white text-primary-250 ";
        else classes += "bg-primary-900";
      }
      return classes;
    },
    [completedSteps, currentStep]
  );

  return (
    <ProgressBar>
      {steps.map((item, index) => (
        <Step key={index}>
          {() => (
            <div className="flex flex-col items-center gap-1">
              <div
                className={`w-16 h-16 rounded-full cursor-default flex justify-center items-center font-bold text-2xl relative border-4 text-primary-500 border-primary-500 ${stepColor(index)} `}
                onClick={() => handleChangeStep(index + 1)}
              >
                {completedSteps.includes(index + 1) ? (
                  <img src="/assets/images/checked.svg" />
                ) : (
                  index + 1
                )}
              </div>
              <div className="text-primary-500 text-base font-medium absolute bottom-[-24px]">
                {item}
              </div>
            </div>
          )}
        </Step>
      ))}
    </ProgressBar>
  );
};

export default StepBar;
