import ConfirmPopup from '@/components/modals/ConfirmPopup';
import { IInterviewsResponse } from '@/module/interviews/response/interviews.interface';
import React from 'react';

export interface IConfirmPaymentModalProps {
  currentData: IInterviewsResponse;
  openPopup: boolean;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  openMaxPopup: boolean;
  setOpenMaxPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleApprove: Function;
  handleFailure: Function;
}

const ConfirmInterviewPopup = ({
  currentData,
  openPopup,
  setOpenPopup,
  openMaxPopup,
  setOpenMaxPopup,
  handleApprove,
  handleFailure,
}: IConfirmPaymentModalProps) => {
  const { currentQuantity, maximumQuantity } = currentData;

  const maxPopupConfig = {
    title: 'リターン個数の上限に達しました',
    confirmText: '閉じる',
    children: (
      <div className="mb-6 mt-4 text-base font-medium text-secondary-500">
        <p>リターン品が個数の上限に達しました。</p>
        <p>これ以上合格にすることはできません</p>
      </div>
    ),
    open: openMaxPopup,
    onConfirm: () => onClickConfirmMax(),
  };

  const interviewpopupConfig = {
    title: '合否判定',
    confirmText: '合格',
    cancelText: '不合格',
    isShowCloseButton: true,
    onClose: () => setOpenPopup(false),
    children: (
      <div className="mb-6 mt-4 text-base font-medium text-secondary-500">
        <p>合格にすると合格メールが送信されます。</p>
        <p>不合格にすると不合格メールが届きます。</p>
      </div>
    ),
    open: openPopup,
    onConfirm: () => onClickConfirm(),
    onCancel: () => onCancelConfirm(),
  };

  const onClickConfirm = () => {
    setOpenPopup(false);
    if (currentQuantity >= maximumQuantity) {
      setOpenMaxPopup(true);
      return;
    }
    handleApprove();
  };

  const onCancelConfirm = () => {
    setOpenPopup(false);
    setOpenMaxPopup(false);
    handleFailure();
  };

  const onClickConfirmMax = () => {
    setOpenMaxPopup(false);
    setOpenPopup(false);
  };

  return (
    <>
      <ConfirmPopup popupConfig={interviewpopupConfig} />
      <ConfirmPopup popupConfig={maxPopupConfig} />
    </>
  );
};

export default ConfirmInterviewPopup;
