import { AuthApi } from "@/module/auth/api";
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";

const axiosHttp = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosHttp.interceptors.request.use(
  (config: any) => {
    const accessToken = localStorage.getItem("accessToken");
    return {
      ...config,
      headers: {
        ...(accessToken !== null && { Authorization: `Bearer ${accessToken}` }),
        'Accept-Language': 'ja',
        ...config.headers,
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const removeFullToken = () => {
  localStorage.removeItem("accessToken")
  localStorage.removeItem("refreshToken")
}

const logout = () => {
  removeFullToken();
  window.location.href = "/auth/basic-authencation"
}

const onResponseError = async (
  err: AxiosError
): Promise<AxiosError | undefined> => {
  const originalConfig = err.config as InternalAxiosRequestConfig

  if (err.response?.status === 401) {
    const currentRefreshToken = localStorage.getItem("refreshToken");
    if (!currentRefreshToken) {
      logout();
      return
    }
    await AuthApi.refreshToken(currentRefreshToken).then((token) => {
      if(!token) removeFullToken();
      localStorage.setItem("accessToken", token.accessToken)
      localStorage.setItem("refreshToken", token.refreshToken)
      originalConfig.headers.Authorization = `Bearer ${token.accessToken}`
    }).catch(() => {
      logout();
    });
    return axiosHttp(originalConfig)
  }
  return Promise.reject(err)
}

axiosHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => onResponseError(error)
);

export default axiosHttp;
