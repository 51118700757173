import { IColumns } from "./type.interface";

export enum ColumnUsersId {
    NAME = "name",
    EMAIL = "email",
    REGISTER_DATE = "register_date",
    UPDATE_DATE = "update_date",
    EDIT = "edit",
    DELETE = "delete",
  }
  
export const ADMIN_USERS_COLUMNS: IColumns[] = [
    {
      id: ColumnUsersId.NAME,
      label: "名前",
      className: "text-left p-2.5 w-[26%]",
    },
    {
      id: ColumnUsersId.EMAIL,
      label: "メールアドレス",
      className: "text-left p-2.5 w-[26%]",
    },
    {
      id: ColumnUsersId.REGISTER_DATE,
      label: "登録日",
      className: "text-left p-2.5 w-[19%]",
    },
    {
      id: ColumnUsersId.UPDATE_DATE,
      label: "更新日",
      className: "text-left p-2.5 w-[19%]",
    },
    {
      id: ColumnUsersId.EDIT,
      label: "編集",
      className: "text-left p-2.5 w-[15%]",
    },
    {
      id: ColumnUsersId.DELETE,
      label: "削除",
      className: "text-left p-2.5 w-[15%]",
    },
  ];