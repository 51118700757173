import { FULL_DATE_TIME_FORMAT } from "@/common/constants/common.constants";
import { format, Locale } from "date-fns";
import ja from "date-fns/locale/ja";
import React from "react";
import { default as DatePicker, default as ReactDatePicker, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FieldError } from "react-hook-form";

registerLocale("ja", ja as unknown as Locale);

export interface ISelectData {
  leftIcon?: React.ReactNode;
  label: string;
  value: string | number;
  rightIcon?: React.ReactNode;
}

interface AppDatePickerProps {
  isShowCalendarIcon?: boolean;
  value: Date;
  minDate?: Date | null;
  maxDate?: Date | null;
  placeholder?: string;
  dateFormat?: string;
  error?: FieldError | undefined;
  showTimeInput?: boolean;
  popperPlacement?: string;
  onChange: (...event: any[]) => void;
}

const AppDatePicker = React.forwardRef<ReactDatePicker<undefined, undefined>, AppDatePickerProps>(
  ({
    value,
    onChange,
    minDate,
    maxDate,
    isShowCalendarIcon = true,
    placeholder,
    error,
    dateFormat = FULL_DATE_TIME_FORMAT,
    popperPlacement = "bottom-start",
    showTimeInput = false,
  }, ref) => {
    const parseDate = (originalValue: Date) => {
      return value instanceof Date && !isNaN(originalValue.getTime()) ? format(originalValue, dateFormat) : "";
    };

    const CustomInput = React.forwardRef<
      HTMLInputElement,
      { value?: string; onClick?: () => void }
    >(({ value, onClick }, ref) => (
      <div
        onClick={onClick}
        className={`w-full flex gap-2 h-[40px] p-2 rounded-[6px] text-[#1c2024] text-[14px] outline-none border-border-input ${error ? "no-outline-important border-2 border-primary-500" : "border"}`}
      >
        {isShowCalendarIcon && <img src="/assets/images/calendar-icon.svg" />}
        <input
          placeholder={placeholder}
          value={value}
          ref={ref}
          readOnly
          className="focus-visible:outline-none w-full bg-transparent"
        />
      </div>
    ));

    const CustomTimeInput = ({
      value,
      onChange,
    }: {
      value?: string;
      onChange: (value: string) => void;
    }) => (
      <input
        value={value}
        disabled={!value}
        placeholder="HH:mm"
        onChange={(e) => onChange(e.target.value)}
        className="p-1"
        style={{ border: "solid 1px" }}
      />
    );

    return (
      <DatePicker
        ref={ref}
        onChange={(date: Date) => onChange(date)}
        minDate={minDate}
        showTimeInput={showTimeInput}
        customTimeInput={
          <CustomTimeInput
            value={parseDate(value)}
            onChange={onChange}
          />
        }
        maxDate={maxDate}
        wrapperClassName="w-full focus:border-[#8da4ef] focus:border"
        dateFormat={dateFormat}
        selected={value instanceof Date && !isNaN(value.getTime()) ? value : null}
        locale="ja"
        popperPlacement={popperPlacement as any}
        customInput={
          <CustomInput value={parseDate(value)} />
        }
      />
    );
  }
);

export default AppDatePicker;
