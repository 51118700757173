import { ERROR_MESSAGE } from '@/common/constants/common.constants';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: ERROR_MESSAGE.required,
  },
  string: {
    min: ({ min }) => `このフィールドは最低${min}文字以上でなければなりません`,
    max: ({ max }) => `このフィールドは最大${max}文字以下でなければなりません`,
    email: '有効なメールアドレスを入力してください',
  },
  number: {
    integer: '整数を入力してください',
    min: ({ min }) => `値は最低でも${min}以上でなければなりません`,
    max: ({ max }) => `値は最大でも${max}以下でなければなりません`,
  },
});

export default yup;
