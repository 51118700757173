import React from "react";

interface DetailCardProps {
  children: React.ReactNode;
  title?: string;
  customeClass?: string;
  variant?: "sub" | "main";
}

const DetailCard: React.FC<DetailCardProps> = ({
  children,
  title,
  customeClass = "px-4 py-7",
  variant = "main",
}) => {
  const generateBgColor = () => {
    if (variant === "sub") {
      return "bg-plate-100";
    }
    return "bg-white";
  };

  const generateTitleBgColor = () => {
    if (variant === "sub") {
      return "bg-plate-200 font-medium text-sm";
    }
    return "bg-plate-100 font-bold text-2xl leading-7";
  };
  return (
    <div
      className={`border border-outline-200 shadow-project-card rounded-2xl ${customeClass} ${generateBgColor()}`}
    >
      {title && (
        <div className={`${generateTitleBgColor()} px-2 py-1 text-primary-400`}>
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

export default DetailCard;
