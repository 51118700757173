import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from "../../../hooks/useAuth";

interface ProtectedRouteProps {
    children: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { getUserToken } = useAuth();
    if (!getUserToken()) {
      // user is not authenticated
      return <Navigate to="/auth/basic-authencation" />;
    }
    return children;
  };

export default ProtectedRoute;