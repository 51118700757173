import React, { memo } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { FieldError } from 'react-hook-form';

interface ITooltipProps extends TooltipPrimitive.TooltipContentProps {
  error?: FieldError | undefined;
  children?: React.ReactNode;
  contentChildren?: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: ((open: boolean) => void) | undefined;
}

const Tooltip: React.FC<ITooltipProps> = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  contentChildren,
  ...props
}) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
        <TooltipPrimitive.Trigger>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content side="top" align="center" {...props}>
          {content && (
            <div className="rounded-md border border-outline-200 bg-white px-2 py-[6px] text-sm font-bold leading-4 text-secondary-500 shadow-card-days">
              {content}
            </div>
          )}
          {contentChildren && contentChildren}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
export default memo(Tooltip);
