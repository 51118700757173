import axios from "@/api/axios";
import {
  IBasicAuthenResponse,
  ILoginResponse,
  IRefreshTokenResponse,
  IVerify2FaResponse,
} from "./response/auth.interface";
import { IResponseResult } from "@/interfaces/common.interface";

export class AuthApi {
  static basicAuth(
    username: string,
    password: string
  ): Promise<IResponseResult<IBasicAuthenResponse>> {
    return axios
      .post("/auth/basic-authen", { username, password })
      .then((res) => res.data);
  }

  static login(
    email: string,
    password: string,
    token: string
  ): Promise<IResponseResult<ILoginResponse>> {
    return axios
      .post("auth/login", { email, password, token })
      .then((res) => res.data);
  }

  static verifyTfaCode(
    email: string,
    tfaCode: string,
    token: string
  ): Promise<IResponseResult<IVerify2FaResponse>> {
    return axios
      .post("auth/verify-2fa", { email, tfaCode, token })
      .then((res) => res.data);
  }

  static resendTwoFAVerification(email: string, token: string) {
    return axios
      .post("auth/resend-2fa", { email, token })
      .then((res) => res.data);
  }

  static verifyBasicAuthen(token: string) {
    return axios
      .post("auth/verify-basic-authen", { token })
      .then((res) => res.data);
  }

  static verifyLogin(token: string) {
    return axios.post("auth/verify-login", { token }).then((res) => res.data);
  }

  static refreshToken(refreshToken: string): Promise<IRefreshTokenResponse> {
    return axios
      .post("/auth/refresh", { refreshToken })
      .then((res) => res.data.data);
  }
}
