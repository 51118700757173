import { FULL_DATE_TIME_FORMAT } from "@/common/constants/common.constants";
import { displayData } from "@/common/helpers";
import AppButton from "@/components/atom/Button/Button";
import ConfirmPopup from "@/components/modals/ConfirmPopup";
import { AuthContext } from "@/contexts/AuthContext";
import { IColumns } from "@/module/adminUsers/type.interface";
import { NOTIFICATION_CATEGORY } from "@/module/notifications/constants";
import { format } from "date-fns";
import React, { useContext } from "react";

interface TableNotificationsProps {
  columns: IColumns[];
  editAction: (adminUserId: string) => void;
  deleteAction: (adminUserId: string) => Promise<void>;
  data: any[];
}

const TableNotifications: React.FC<TableNotificationsProps> = ({
  columns,
  editAction,
  deleteAction,
  data,
}) => {
  const { user } = useContext(AuthContext);
  const popupConfig = {
    title: "通知の削除",
    children: (
      <div className="text-secondary-500 font-medium mt-4 mb-6 text-base">
        <p>
          こちらの通知を削除してよろしいですか？この操作は取り消しできません
        </p>
      </div>
    ),
    cancelText: "キャンセル",
    confirmText: "削除",
  };
  return (
    <div className="max-h-table border-separate overflow-clip flex flex-col border rounded-xl bg-white border-outline-200 px-4 py-9">
      <table className="table-fixed w-full">
        <thead className="sticky top-0">
          <tr>
            {columns.map((column) => (
              <th id={column.id} key={column.id} className={column.className}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      {data.length === 0 ? (
        <>
          <div className="text-center text-2xl font-bold truncate text-secondary-400 py-10">
            データ無し
          </div>
        </>
      ) : (
        <div className="flex-1 overflow-y-auto">
          <table className="w-full table-fixed">
            <tbody>
              {data.map((row, index) => (
                <tr className="odd:bg-plate-300" key={index}>
                  <td className="text-left text-base font-medium truncate text-secondary-400 p-2 w-[26%]">
                    {
                      NOTIFICATION_CATEGORY[
                        row.category as keyof typeof NOTIFICATION_CATEGORY
                      ]
                    }
                  </td>
                  <td className="text-left text-base font-medium truncate text-secondary-400 p-2 w-[26%]">
                    {displayData(row.title)}
                  </td>
                  <td className="text-left text-base font-medium text-secondary-400 p-2  w-[19%]">
                    {displayData(format(row.startDate, FULL_DATE_TIME_FORMAT))}
                  </td>
                  <td className="text-left text-base font-medium text-secondary-400 p-2  w-[19%]">
                    {displayData(format(row.endDate, FULL_DATE_TIME_FORMAT))}
                  </td>
                  <td className="text-left p-2 w-[15%]">
                    <AppButton
                      text="編集"
                      size="s-md"
                      onClick={() => editAction(row.id)}
                    />
                  </td>
                  <td className="text-left p-2 w-[15%]">
                    {row?.id !== user?.id ? (
                      <ConfirmPopup
                        popupConfig={{
                          ...popupConfig,
                          onConfirm: () => deleteAction(row.id),
                        }}
                      >
                        <AppButton
                          text="削除"
                          size="s-md"
                          variant="outline"
                          custome="border-red-450 text-red-450 lg:focus:text-red-450 lg:hover:text-red-450 lg:hover:bg-transparent lg:focus:bg-transparent"
                        />
                      </ConfirmPopup>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TableNotifications;
