import React from "react";

const Spinner: React.FC = () => {
  return (
    <div className=" z-[10000] bg-white w-[109px] h-[109px] rounded-lg p-8 flex flex-col justify-center items-center gap-[10px]">
      <img className="h-[24px] w-[24px] animate-spin" src="/assets/images/spinner.svg" alt="" />
      <div className="text-primary-500 text-center text-[11px] font-medium">処理中</div>
      
    </div>
  );
};

export default Spinner;
