import { EStatusProject } from "@/module/projectsList/constants";

interface LabelCardProps {
  status: EStatusProject;
}

type PropertyLabels = {
  [key in EStatusProject]: { text: string; icon: string; className: string };
};

const propertyLabels: PropertyLabels = {
  [EStatusProject.CREATED]: {
    text: "",
    icon: "",
    className: "",
  },
  [EStatusProject.DRAFT]: {
    text: "下書き",
    icon: "",
    className: "bg-secondary text-white",
  },
  [EStatusProject.PUBLISHED]: {
    text: "",
    icon: "",
    className: "",
  },
  [EStatusProject.EDITING]: {
    text: "",
    icon: "",
    className: "",
  },
};
const LabelCard: React.FC<LabelCardProps> = ({ status }) => {
  return (
    <div
      className={`${propertyLabels[status]?.className} p-2 gap-2 text-center flex rounded-xl`}
    >
      {propertyLabels[status]?.icon && (
        <img src={propertyLabels[status]?.icon} alt="" />
      )}
      <p className="text-sm font-bold">{propertyLabels[status]?.text}</p>
    </div>
  );
};
export default LabelCard;
