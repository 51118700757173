import TextField from "@/components/atom/TextField/TextField";
import { TextField as Text } from "@radix-ui/themes";
import Heading1 from "@/components/common/Heading1";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TableSupporters from "./components/TableSupporters";
import { debounce } from "lodash";
import { handleException } from "@/common/exceptions/exception-handling";
import { useLoading } from "@/hooks/useLoading";
import { ISupporterResponse } from "@/module/supportersList/response/supportersList.interface";
import { SupportersApi } from "@/module/supportersList/api";
import { ISupporterParams } from "@/module/supportersList/request/supportersList.interface";
import { SUPPORTERS_COLUMNS } from "@/module/supportersList/constants";
import * as toast from "@/common/helpers/toast";
import AppButton from "@/components/atom/Button/Button";

interface SupportersListPageProps {}

const SupportersListPage: React.FC<SupportersListPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId } = useParams() || "";
  const search = searchParams.get("search") || "";
  const [listSupporters, setListSupporters] = useState<ISupporterResponse[]>([]);
  const [value, setValue] = useState(search);
  const { openLoading, closeLoading } = useLoading();

  const fetchAllSupporters = async (params?: ISupporterParams) => {
    try {
      openLoading();
      const response = await SupportersApi.getAllSupporters(
        projectId || "",
        params,
      );
      return response.data;
    } catch (error) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
      return [];
    } finally {
      closeLoading();
    }
  };

  const debouncedSearch = useRef(
    debounce(async (params) => {
      return await setListSupporters(await fetchAllSupporters({ search: params }));
    }, 300),
  ).current;

  useEffect(() => {
    fetchAllSupporters({ search })
      .then((response) => {
        setListSupporters(response);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
    setValue(event.target.value);
    navigate(`/projects/${projectId}/supporters?search=${event.target.value}`);
  };

  const handleBack = () => {
    navigate(`/projects/${projectId}/dashboard`);
  };

  const handleExportCSV = async () => {
    if (!projectId) return;
    try {
      openLoading();
      await SupportersApi.exportCsv(projectId);
      toast.showSuccess("CSV 出力が完了しました");
    } catch (error) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };

  return (
    <div className="px-20 py-16">
      <Heading1 text="支援者一覧" backAction={handleBack} />
      <div className="my-10">
        <div className="my-10 w-full flex gap-10">
          <div className="flex-1">
            <TextField
              placeholder="メールアドレス検索"
              value={value}
              onChange={(value) => onSearch(value)}
            >
              <Text.Slot></Text.Slot>
              <Text.Slot>
                <img src="/assets/images/search-icon.svg" alt="" />
              </Text.Slot>
            </TextField>
          </div>
          <AppButton
            text="CSV 出力"
            size="s-md"
            onClick={handleExportCSV}
          />
        </div>
      </div>
      <div className="">
        <TableSupporters columns={SUPPORTERS_COLUMNS} data={listSupporters} />
      </div>
    </div>
  );
};

export default SupportersListPage;
