import { BE_MESSAGE } from "@/common/constants/common.constants";
import Heading1 from "@/components/common/Heading1";
import { AdminUsersApi } from "@/module/adminUsers/api";
import { RegisterFormInputs } from "@/module/adminUsers/type.interface";
import { USERS_SCHEMA } from "@/module/adminUsers/validations/adminUsers.validations";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import RegeisterForm from "../components/RegeisterForm";
import { handleException } from "@/common/exceptions/exception-handling";

interface RegisterPageProps {}

const RegisterPage: React.FC<RegisterPageProps> = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormInputs>({
    resolver: yupResolver(USERS_SCHEMA),
  });
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const onSubmit: SubmitHandler<RegisterFormInputs> = async (
    data: RegisterFormInputs
  ) => {
    try {
      const {
        firstName,
        lastName,
        firstNameKana,
        lastNameKana,
        email,
        tel,
        password,
      } = data;
      const body = {
        lastName,
        firstName,
        lastNameKana,
        firstNameKana,
        password,
        email,
        tel,
      };
      const response = await AdminUsersApi.register(body);
      if (response) {
        navigate(`/admin-users`);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && setErrorMsg(errorMessage);
    }
  };

  const backAction = () => {
    navigate(`/admin-users`);
  };

  return (
    <div className="px-20 py-16">
      <Heading1
        text="管理者新規登録"
        backAction={backAction}
        className={"mb-10"}
      />
      <RegeisterForm
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        errorMsg={errorMsg}
        textSubmit="登録"
      />
    </div>
  );
};

export default RegisterPage;
