import React, { HTMLAttributes, memo, useMemo } from "react";

interface IAlertProps extends HTMLAttributes<HTMLDivElement> {
  variant?: "error" | "success" | "info" | "warning";
  message: string | string[];
  alertTitle?: string;
}

const Alert: React.FC<IAlertProps> = (props) => {
  const { variant = "info", className, message, alertTitle, ...rest } = props;

  const _classname = useMemo(() => {
    // Def
    let classname = "w-full p-4 mb-4 text-sm rounded-lg dark:bg-gray-800 ";
    switch (variant) {
      case "success":
        classname += "text-green-800 bg-green-50 dark:text-green-400";
        break;
      case "error":
        classname += "text-red-450 bg-red-50 dark:text-red-400";
        break;

      case "warning":
        classname += "text-yellow-800 bg-yellow-50 dark:text-yellow-400";
        break;

      default:
        classname += "text-blue-800 bg-blue-50 dark:text-blue-400";
    }
    if (className) classname += ` ${className}`;
    return classname;
  }, [className, variant]);

  const _title = useMemo(() => {
    let text = "";
    if (alertTitle) text = alertTitle;
    else text = variant.toUpperCase();
    return text;
  }, [alertTitle, variant]);

  const renderMessage = useMemo(() => {
    if (typeof message === "string") {
      return message; // or any processing you want to do with the string
    } else {
      return (
        <ul className="mt-1.5 list-disc list-inside">
          {message.map((item) => (
            <li className="capitalize">{item}</li>
          ))}
        </ul>
      );
    }
  }, [message]);

  return (
    <div {...rest} className={_classname}>
      {alertTitle && (
        <div className="flex">
          <svg
            className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="font-medium">{_title}</span>
        </div>
      )}

      {renderMessage}
    </div>
  );
};

export default memo(Alert);
