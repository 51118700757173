import { formatFullDate } from "@/common/helpers";
import AppButton from "@/components/atom/Button/Button";
import ConfirmPopup from "@/components/modals/ConfirmPopup";
import { AuthContext } from "@/contexts/AuthContext";
import { IAdminUsersResponse } from "@/module/adminUsers/response/adminUsers.interface";
import { IColumns } from "@/module/adminUsers/type.interface";
import React, { useContext } from "react";

interface TableAdminUsersProps {
  columns: IColumns[];
  editAction: (adminUserId: string) => void;
  deleteAction: (adminUserId: string) => Promise<void>;
  data: IAdminUsersResponse[];
}

const TableAdminUsers: React.FC<TableAdminUsersProps> = ({
  columns,
  editAction,
  deleteAction,
  data,
}) => {
  const { user } = useContext(AuthContext);
  const popupConfig = {
    title: "以下の管理者を削除しますか？",
    cancelText: "キャンセル",
  };
  return (
    <div className="max-h-table border-separate overflow-clip flex flex-col border rounded-xl bg-white border-outline-200 px-4 py-9">
      <table className="table-fixed w-full">
        <thead className="sticky top-0">
          <tr>
            {columns.map((column) => (
              <th id={column.id} key={column.id} className={column.className}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div className="flex-1 overflow-y-auto">
        <table className="w-full table-fixed">
          <tbody>
            {data.length === 0 && (
              <>
                <div className="text-center text-2xl font-bold truncate text-secondary-400 py-10">
                  データ無し
                </div>
              </>
            )}
            {data.map((row, index) => (
              <tr className="odd:bg-plate-300" key={index}>
                <td className="text-left text-base font-medium truncate text-secondary-400 p-2 w-[26%]">
                  {row.firstName} {row.lastName}
                </td>
                <td className="text-left text-base font-medium truncate text-secondary-400 p-2 w-[26%]">
                  {row.email}
                </td>
                <td className="text-left text-base font-medium text-secondary-400 p-2  w-[19%]">
                  {formatFullDate(row.createdAt)}
                </td>
                <td className="text-left text-base font-medium text-secondary-400 p-2  w-[19%]">
                  {formatFullDate(row.updatedAt)}
                </td>
                <td className="text-left p-2 w-[15%]">
                  <AppButton
                    text="編集"
                    size="s-md"
                    onClick={() => editAction(row.id)}
                  />
                </td>
                <td className="text-left p-2 w-[15%]">
                  {row?.id !== user?.id ? (
                    <ConfirmPopup
                      popupConfig={{
                        ...popupConfig,
                        description: `名前：${row.firstName} ${row.lastName}`,
                        customeBtn: (
                          <AppButton
                            onClick={() => deleteAction(row.id)}
                            text={"削除"}
                            size="sm"
                            custome="bg-red-450 px-0 flex-1"
                          ></AppButton>
                        ),
                      }}
                    >
                      <AppButton
                        text="削除"
                        size="s-md"
                        variant="outline"
                        custome="border-red-450 text-red-450 lg:focus:text-red-450 lg:hover:text-red-450 lg:hover:bg-transparent lg:focus:bg-transparent"
                      />
                    </ConfirmPopup>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableAdminUsers;
