import { Outlet } from "react-router-dom";
import "./RootLayout.css";
import SideBar from "../../components/SideBar/SideBar";
import { tabs } from "@/common/constants/user-page.constants";

const RootLayout: React.FC = () => {
  return (
    <div className="layout flex h-screen bg-no-repeat">
      <SideBar tabs={tabs}  />
      <main className="overflow-auto h-full w-full">
        <Outlet />
      </main>
    </div>
  );
};

export default RootLayout;
