import React, { memo, useCallback, useMemo, useRef } from "react";
import ReactQuill, { ReactQuillProps, Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.css";
import { handleException } from "@/common/exceptions/exception-handling";
import * as toast from "@/common/helpers/toast";
import { uploadAndReplace } from "@/common/helpers/api";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);
interface ITextEditorProps extends ReactQuillProps {
  CustomToolbar?: JSX.Element;
  customToolbarContainer?: string;
  formats?: string[];
  containerClassname?: string;
  /**
   * Use this classname before the Quill css class to override it.
   */
  editorClassName?: string;
  onEditorChange: (content: string) => void;
}

const TextEditor: React.FC<ITextEditorProps> = (props) => {
  const {
    customToolbarContainer = "#toolbar",
    formats,
    CustomToolbar,
    containerClassname,
    editorClassName = "border-rounded",
    onEditorChange,
  } = props;
  const reactQuillRef: any = useRef(null);

  const handleEditorChange = useCallback(
    (content: string) => {
      onEditorChange(content);
    },
    [onEditorChange]
  );

  const editorFormats = useMemo(() => {
    return (
      formats ?? [
        "header",
        "font",
        "bold",
        "italic",
        "underline",
        "code-block",
        "list",
        "bullet",
        "indent",
        "image",
        "style",
        "width",
        "height",
      ]
    );
  }, [formats]);

  const renderToolbar = useMemo(() => {
    return (
      CustomToolbar ?? (
        <div id="toolbar">
          <button className="ql-header heading-button" value="1">
            H1
          </button>
          <button className="ql-header heading-button" value="2">
            H2
          </button>

          <button className="ql-header heading-button" value="3">
            H3
          </button>
          <button className="ql-header heading-button" value="4">
            H4
          </button>
          <button className="ql-header heading-button" value="5">
            H5
          </button>
          <button className="ql-header heading-button" value="6">
            H6
          </button>

          <div className="toolbar-divider" />

          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <div className="toolbar-divider" />

          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>

          <div className="toolbar-divider" />

          <button className="ql-code-block"></button>

          <div className="toolbar-divider" />

          <button className="ql-image"></button>
        </div>
      )
    );
  }, [CustomToolbar]);

  const _containerClassName = useMemo(() => {
    let defaultClassName = "rounded-lg border border-border-input p-2";
    if (containerClassname) return `${defaultClassName} ${containerClassname}`;
    return defaultClassName;
  }, [containerClassname]);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        try {
          const file = input.files[0];
          const quill = reactQuillRef.current;
          if (quill) {
            const range = quill.getEditorSelection();
            // Insert a temporary loading image at the current cursor position
            quill
              .getEditor()
              .insertEmbed(range.index, "image", "/assets/images/loading.gif");
            // Start the upload
            const url = await uploadAndReplace(file);
            // Replace the temporary loading image with the actual image
            quill.getEditor().deleteText(range.index, 1);
            quill.getEditor().insertEmbed(range.index, "image", url.fileUrl);
          }
        } catch (error: any) {
          const { errorMessage } = handleException(error);
          errorMessage && toast.showError(errorMessage);
        }
      }
    };
  }, []);

  return (
    <div className={_containerClassName}>
      {renderToolbar}
      <div className="m-auto w-full h-[2px] bg-outline-200"></div>
      <ReactQuill
        {...props}
        ref={reactQuillRef}
        className={editorClassName}
        modules={{
          imageResize: {
            parchment: Quill.import("parchment"),
            modules: ["Resize", "DisplaySize"],
          },
          toolbar: {
            container: customToolbarContainer,
            handlers: {
              image: imageHandler,
            },
          },
        }}
        formats={editorFormats}
        onChange={(value) => handleEditorChange(value)}
      />
    </div>
  );
};
export default memo(TextEditor);
