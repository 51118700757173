import { UploadApi } from "@/module/upload/api";

export interface IImgData {
  fileUrl: string;
  fileName: string;
}

export const uploadAndReplace = async (file: File): Promise<IImgData> => {
  const nameFile = file.name;
  const uploadData = await UploadApi.UploadImage(nameFile);
  await UploadApi.PutImage(file, uploadData.data.data.fileUrl);
  const getData = await UploadApi.GetImage(uploadData.data.data.fileName);

  return {
    fileUrl: getData.data.data.fileUrl,
    fileName: uploadData.data.data.fileName,
  };
};

export const uploadPublicAndReplace = async (file: File): Promise<string> => {
  const uploadData = await UploadApi.UploadImagePublic(file);
  return uploadData.data.data.fileUrl;
};
