import Heading1 from "@/components/common/Heading1";
import React, { useContext, useEffect, useRef, useState } from "react";
import TableUsers from "./components/TableUsers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { USERS_COLUMNS } from "@/module/users/constants";
import TextField from "@/components/atom/TextField/TextField";
import { TextField as Text } from "@radix-ui/themes";
import { debounce } from "lodash";
import { UsersApi } from "@/module/users/api";
import { IUsersBody } from "@/module/users/request/users.interface";
import { IUsersResponse } from "@/module/users/response/users.interface";
import { FilterUsersContext } from "@/contexts/FilterContext";
import { useLoading } from "@/hooks/useLoading";
import * as toast from "@/common/helpers/toast";
import { handleException } from "@/common/exceptions/exception-handling";
import AppButton from "@/components/atom/Button/Button";
interface UsersPageProps {}

const UsersPage: React.FC<UsersPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const [listUsers, setListUsers] = useState<IUsersResponse[]>([]);
  const [value, setValue] = useState(search);
  const { openLoading, closeLoading } = useLoading();

  const { setCurrentSearch } = useContext(FilterUsersContext);

  const fetchAllUsers = async (params?: IUsersBody) => {
    try {
      openLoading();
      const response = await UsersApi.getAllUsers(params);
      return response.data;
    } catch (error) {
      handleException(error);
      return [];
    } finally {
      closeLoading();
    }
  };

  const debouncedSearch = useRef(
    debounce(async (params) => {
      return await setListUsers(await fetchAllUsers({ search: params }));
    }, 500),
  ).current;

  useEffect(() => {
    fetchAllUsers({ search })
      .then((response) => {
        setListUsers(response);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const previewAction = (userId: string) => {
    navigate(`/users/preview/${userId || ""}`);
    setCurrentSearch(value);
  };

  const deleteAction = async (adminUserId: string) => {
    try {
      openLoading();
      await UsersApi.deleteUser(adminUserId);
      setListUsers(await fetchAllUsers({ search: value }));
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
    setValue(event.target.value);
    navigate(`/users?search=${event.target.value}`);
  };

  const handleExportCSV = async () => {
    try {
      openLoading();
      await UsersApi.exportCsv();
      toast.showSuccess("CSV 出力が完了しました");
    } catch (error) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };

  return (
    <div className="px-20 py-16">
      <Heading1 text="ユーザー情報一覧" />
      <div className="my-10 w-full flex gap-10">
        <div className="flex-1">
          <TextField
            placeholder="メールアドレス検索"
            value={value}
            onChange={(value) => onSearch(value)}
          >
            <Text.Slot></Text.Slot>
            <Text.Slot>
              <img src="/assets/images/search-icon.svg" alt="" />
            </Text.Slot>
          </TextField>
        </div>
        <AppButton
          text="CSV 出力"
          size="s-md"
          onClick={handleExportCSV}
        />
      </div>
      <div className="">
        <TableUsers
          columns={USERS_COLUMNS}
          previewAction={previewAction}
          deleteAction={deleteAction}
          data={listUsers}
        />
      </div>
    </div>
  );
};

export default UsersPage;
