import { IColumns } from "./type.interface";

export enum ColumnSupportersId {
  NAME = "name",
  EMAIL = "email",
  SUPPORT_DATE = "support_date",
  AMOUNT = "amount",
  SUPPORTER_NAME = "supporter_name",
}

export const SUPPORTERS_COLUMNS: IColumns[] = [
  {
    id: ColumnSupportersId.NAME,
    label: "リターン名",
    className: "text-left p-2.5 w-[25%]",
  },
  {
    id: ColumnSupportersId.EMAIL,
    label: "メールアドレス",
    className: "text-left p-2.5 w-[25%]",
  },
  {
    id: ColumnSupportersId.SUPPORTER_NAME,
    label: "支援者名",
    className: "text-left p-2.5 w-[12%]",
  },
  {
    id: ColumnSupportersId.SUPPORT_DATE,
    label: "支援日",
    className: "text-left p-2.5 w-[10%]",
  },
  {
    id: ColumnSupportersId.AMOUNT,
    label: "支援金額",
    className: "text-center p-2.5 w-[28%]",
  },
];
