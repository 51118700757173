import React from "react";
import { FieldError } from "react-hook-form";
import Select, { components } from "react-select";
import clsx from "clsx";

export interface ISelectData {
  leftIcon?: React.ReactNode;
  label: string;
  value: string | number;
  rightIcon?: React.ReactNode;
}

interface AppSelectProps {
  error?: FieldError | undefined;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  items: ISelectData[];
  placeholder?: string;
  value: string | number;
  defaultValue?: string | number;
  onChange: (...event: any[]) => void;
}

const AppSelect = React.forwardRef<HTMLInputElement, AppSelectProps>(
  (props) => {
    const controlStyles = {
      base: "border rounded-lg bg-white hover:cursor-pointer",
      focus: "border-[#e5e7eb] ring-1 ring-primary-500",
      nonFocus: "border-gray-300 hover:border-gray-400",
    };
    const { items, placeholder, defaultValue, value, onChange, error } = props;
    return (
      <Select
        components={{
          Placeholder,
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        defaultValue={items.find((c) => c.value === defaultValue)}
        placeholder={placeholder}
        options={items}
        value={items.find((c) => c.value === value)}
        onChange={onChange}
        formatOptionLabel={formatOptionLabel}
        className={` ${error ? 'no-outline-important border-2 border-primary-500' : ''}`}
        classNames={{
          control: ({ isFocused }) =>
            clsx(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base
            ),
        }}
      />
    );
  }
);

const Placeholder = (props: any) => {
  return <components.Placeholder {...props} />;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props} className="">
      <img src="/assets/images/dropdown-icon.svg" />
    </components.DropdownIndicator>
  );
};

const formatOptionLabel = ({ label, leftIcon, rightIcon }: any) => (
  <>
    {leftIcon && leftIcon}
    <div>{label}</div>
    {rightIcon && rightIcon}
  </>
);

export default AppSelect;
