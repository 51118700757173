import Heading1 from "@/components/common/Heading1";
import React, { useEffect, useRef, useState } from "react";
import TableInterviews from "./components/TableInterviews";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TextField from "@/components/atom/TextField/TextField";
import { TextField as Text } from "@radix-ui/themes";
import { debounce } from "lodash";
import { useLoading } from "@/hooks/useLoading";
import { INTERVIEWS_COLUMNS } from "@/module/interviews/constants";
import { IInterviewsResponse } from "@/module/interviews/response/interviews.interface";
import { IInterviewsParams } from "@/module/interviews/request/interviews.interface";
import { InterviewsApi } from "@/module/interviews/api";
import { handleException } from "@/common/exceptions/exception-handling";
import * as toast from "@/common/helpers/toast";
import AppButton from "@/components/atom/Button/Button";

interface InterviewsPageProps {}

const InterviewsPage: React.FC<InterviewsPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId } = useParams() || "";
  const search = searchParams.get("search") || "";
  const [lisInterviews, setLisInterviews] = useState<IInterviewsResponse[]>([]);
  const [value, setValue] = useState(search);
  const { openLoading, closeLoading } = useLoading();

  const fetchAllInterviews = async (params?: IInterviewsParams) => {
    try {
      openLoading();
      const response = await InterviewsApi.getAllInterviews(
        projectId || "",
        params,
      );
      return response.data;
    } catch (error) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
      return [];
    } finally {
      closeLoading();
    }
  };

  const debouncedSearch = useRef(
    debounce(async (params) => {
      return await setLisInterviews(
        await fetchAllInterviews({ search: params }),
      );
    }, 300),
  ).current;

  useEffect(() => {
    fetchAllInterviews({ search })
      .then((response) => {
        setLisInterviews(response);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
    setValue(event.target.value);
    navigate(`/projects/${projectId}/interviews?search=${event.target.value}`);
  };

  const onBack = () => {
    navigate(`/projects/${projectId}/dashboard`);
  };

  const handleExportCSV = async () => {
    if (!projectId) return;
    try {
      openLoading();
      await InterviewsApi.exportCsv(projectId);
      toast.showSuccess("CSV 出力が完了しました");
    } catch (error) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };

  return (
    <div className="px-20 py-16">
      <Heading1 text="面談一覧" backAction={onBack} />
      <div className="my-10 w-full flex gap-10">
        <div className="flex-1">
          <TextField
            placeholder="メールアドレス検索"
            value={value}
            onChange={(value) => onSearch(value)}
          >
            <Text.Slot></Text.Slot>
            <Text.Slot>
              <img src="/assets/images/search-icon.svg" alt="" />
            </Text.Slot>
          </TextField>
        </div>
        <AppButton
          text="CSV 出力"
          size="s-md"
          onClick={handleExportCSV}
        />
      </div>
      <div className="">
        <TableInterviews
          columns={INTERVIEWS_COLUMNS}
          data={lisInterviews}
          setData={setLisInterviews}
        />
      </div>
    </div>
  );
};

export default InterviewsPage;
