import axios from '@/api/axios';
import { IResponseResult } from '@/interfaces/common.interface';
import { INotificationsResponse } from './response/notifications.interface';
import { INotificationFormBody } from './request/notifications.interface';

export class NotificationsApi {
    static createNotification(body: INotificationFormBody) {
        return axios.post('notifications', body);
    }

    static getAllNotifications(): Promise<IResponseResult<INotificationsResponse[]>> {
        return axios.get('notifications').then((res) => res.data);
    }

    static getNotification(notificationId: string): Promise<IResponseResult<INotificationsResponse>> {
        return axios.get(`notifications/${notificationId}`).then((res) => res.data);
    }

    static editNotification(notificationId: string, body: INotificationFormBody) {
        return axios.put(`notifications/${notificationId}`, body);
    }

    static deleteNotification(notificationId: string) {
        return axios.delete(`notifications/${notificationId}`);
    }
}