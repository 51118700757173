import AppFormField from "@/components/atom/AppFormField";
import AppButton from "@/components/atom/Button/Button";
import PasswordField from "@/components/atom/PasswordField/PasswordField";
import TextField from "@/components/atom/TextField/TextField";
import { RegisterFormInputs } from "@/module/adminUsers/type.interface";
import React from "react";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";

interface RegeisterFormProps {
  onSubmit: SubmitHandler<RegisterFormInputs>;
  errorMsg?: string;
  handleSubmit: UseFormHandleSubmit<RegisterFormInputs, undefined>;
  register: UseFormRegister<RegisterFormInputs>;
  errors: FieldErrors<RegisterFormInputs>;
  textSubmit: string;
}

const RegeisterForm: React.FC<RegeisterFormProps> = ({
  onSubmit,
  errorMsg,
  handleSubmit,
  register,
  errors,
  textSubmit,
}) => {
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-14 flex flex-col items-center"
    >
      <div className="flex flex-col gap-6 w-full">
        <AppFormField
          textLabel="姓"
          required={true}
          errorText={errors.firstName?.message}
        >
          <TextField
            placeholder="山田"
            error={errors.firstName}
            {...register("firstName")}
          ></TextField>
        </AppFormField>

        <AppFormField
          textLabel="名"
          required={true}
          errorText={errors.lastName?.message}
        >
          <TextField
            placeholder="太郎"
            error={errors.lastName}
            {...register("lastName")}
          ></TextField>
        </AppFormField>

        <AppFormField
          textLabel="せい"
          required={true}
          errorText={errors.firstNameKana?.message}
        >
          <TextField
            placeholder="やまだ"
            error={errors.firstNameKana}
            {...register("firstNameKana")}
          ></TextField>
        </AppFormField>

        <AppFormField
          textLabel="めい"
          required={true}
          errorText={errors.lastNameKana?.message}
        >
          <TextField
            placeholder="たろう"
            error={errors.lastNameKana}
            {...register("lastNameKana")}
          ></TextField>
        </AppFormField>

        <AppFormField
          textLabel="メールアドレス"
          required={true}
          errorText={errors.email?.message}
        >
          <TextField
            error={errors.email}
            placeholder="arigatomax@net.com"
            {...register("email")}
          ></TextField>
        </AppFormField>

        <AppFormField
          textLabel="電話番号"
          required={true}
          errorText={errors.tel?.message}
        >
          <TextField
            placeholder="09012345678"
            type="number"
            error={errors.tel}
            {...register("tel")}
          ></TextField>
        </AppFormField>

        <AppFormField
          textLabel="パスワード"
          required={true}
          errorText={errors.password?.message}
          hint={
            errors.password
              ? ""
              : "英大文字、英小文字、数字、記号(!@#$%+-=)をそれぞれ1文字以上用いて6~32文字でご入力ください"
          }
        >
          <PasswordField
            placeholder="●●●●●●●●●●●"
            type="password"
            error={errors.password}
            {...register("password")}
          ></PasswordField>
        </AppFormField>

        <AppFormField
          textLabel="パスワード再入力"
          required={true}
          errorText={errors.rePassword?.message}
        >
          <PasswordField
            placeholder="●●●●●●●●●●●"
            type="password"
            error={errors.rePassword}
            {...register("rePassword")}
          ></PasswordField>
        </AppFormField>
      </div>
      {errorMsg && <span className="text-red-500 text-xs">{errorMsg}</span>}

      <AppButton text={textSubmit} size="lg" type="submit" />
    </form>
  );
};

export default RegeisterForm;
