import AppButton from '@/components/atom/Button/Button';
import Heading1 from '@/components/common/Heading1';
import { useAffiliate } from '@/contexts/AffiliateContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreateAffiliateSuccessPage = () => {
  const { affiliateLink } = useAffiliate();
  const [isShowInviteLinkTooltip, setIsShowInviteLinkTooltip] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/affiliate`);
  };

  const handleCopyAffiliateLink = () => {
    navigator.clipboard
      .writeText(affiliateLink ?? '')
      .then(() => {
        setIsShowInviteLinkTooltip(true);
        setTimeout(() => {
          setIsShowInviteLinkTooltip(false);
        }, 300);
      })
      .catch((err) => {
        console.error('Failed to copy invite link: ', err);
      });
  };

  useEffect(() => {
    if (!affiliateLink) {
      return handleBack();
    }
  }, [affiliateLink]);

  return (
    <div className="px-20 py-16 space-y-8">
      <Heading1 text="アフィリエイト - 登録完了" backAction={handleBack} />
      <div className="text-sm text-secondary-400 font-medium">
        <p>ユーザーに対してリンクが発行されました。</p>
        <p>{'ユーザーは「プロフィール > アフィリエイト」からURLを確認することができます。'}</p>
      </div>
      <div>
        <label className="text-sm text-primary-400 font-bold leading-4">URL</label>
        <button
          className="flex items-center px-2 w-full gap-2 h-[44px] border-2 bg-gray-50 rounded relative"
          onClick={handleCopyAffiliateLink}
        >
          <span className="flex-1 text-right text-xs text-secondary-500 font-medium">{affiliateLink}</span>
          <img src="/assets/images/copy-icon.svg" alt="copy-icon" />
          {isShowInviteLinkTooltip && (
            <div className="absolute -top-[85%] right-0 px-[8px] py-[4px] bg-white shadow text-black text-sm rounded-[4px]">
              URLがコピーされました！
            </div>
          )}
        </button>
      </div>
      <div className="flex justify-center !mt-16">
        <AppButton type="button" text="ユーザー選択に戻る" size="lg" onClick={handleBack} />
      </div>
    </div>
  );
};
