import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { handleException } from '@/common/exceptions/exception-handling';
import { cleanObject } from '@/common/helpers';
import { uploadAndReplace, uploadPublicAndReplace } from '@/common/helpers/api';
import * as toast from '@/common/helpers/toast';
import Heading1 from '@/components/common/Heading1';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import { useLoading } from '@/hooks/useLoading';
import { ProjectsListApi } from '@/module/projectsList/api';
import { EProjectCategory, EStatusProject } from '@/module/projectsList/constants';
import {
  IProject,
  IProjectForm,
  IProjectPayload,
  IStepFourForm,
  IStepOneForm,
  IStepThreeForm,
  IStepTwoForm,
} from '@/module/projectsList/project.interface';
import { FORM_SCHEMA } from '@/module/projectsList/validations/project.validation';
import { STEP_FOUR_MODEL } from '@/module/projectsList/validations/stepFour.validation';
import { STEP_ONE_MODEL } from '@/module/projectsList/validations/stepOne.validations';
import { STEP_TWO_MODEL } from '@/module/projectsList/validations/stepTwo.validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import StepBar from '../components/StepBar';
import StepFour from '../components/StepFour/StepFour';
import StepOne from '../components/StepOne/StepOne';
import StepThree, { STEP3_SCREEN } from '../components/StepThree/StepThree';
import StepTwo from '../components/StepTwo/StepTwo';

interface CreateProjectPageProps {}

const CreateProjectPage: React.FC<CreateProjectPageProps> = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState('プロジェクト新規作成ページ');
  const [step, setStep] = useState(1);
  const [completedSteps, setcompletedSteps] = useState<number[]>([]);
  const [allStepsData, setAllStepsData] = useState<IProjectForm>({} as IProjectForm);
  const [allStepsDataJson, setAllStepsDataJson] = useState<string | undefined>();
  const [isDirtyForm, setIsDirtyForm] = useState<boolean | undefined>();

  const [createError, setCreateError] = useState<string | string[] | undefined>();
  const [currentScreen, setCurrentScreen] = useState<STEP3_SCREEN>(STEP3_SCREEN.list);

  const { openLoading, closeLoading } = useLoading();

  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<IProject>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      projectTitle: allStepsData.title,
      projectOverview: allStepsData.summary,
      thumbnailImg: allStepsData.thumbnailUrl,
      targetAmount: allStepsData.targetApAmount,
      startDate: new Date(allStepsData?.startDate),
      endDate: new Date(allStepsData.endDate),
      nameExecutor: allStepsData.practitionerName,
      profileExecutor: allStepsData.practitionerProfile,
      iconImgExecutor: allStepsData.practitionerThumbnailUrl,
      xLink: allStepsData?.xUrl,
      insLink: allStepsData?.instagramUrl,

      description: allStepsData.content,

      returns: allStepsData.returns,

      companyName: allStepsData?.companyName,
      businessAddress: allStepsData?.businessAddress,
      necessaryExpenses: allStepsData?.necessaryExpenses,
      otherInformation: allStepsData?.otherInformation,
      businessTel: allStepsData?.businessTel,
    },
  });

  const handleNextStep = (step?: number, data?: IStepOneForm | IStepTwoForm | IStepThreeForm | IStepFourForm) => {
    if (step) nextStepNumber(step);
    if (data) setAllStepsData({ ...allStepsData, ...data });
  };

  const handleChangeStep = (step: number) => {
    nextStepNumber(step);
  };

  const nextStepNumber = (stepNumber: number) => {
    switch (stepNumber) {
      case 1:
        setStep(1);
        break;
      case 2:
        setStep(2);
        break;
      case 3:
        setStep(3);
        setCurrentScreen(STEP3_SCREEN.list);
        break;
      case 4:
        setStep(4);
        break;
      default:
        setStep(1);
    }
    generateTitle(stepNumber);
  };

  const onBackClick = () => {
    if (step === 1) {
      navigate('/home');
      return;
    }
    if (currentScreen === STEP3_SCREEN.form) {
      setStep(3);
      setCurrentScreen(STEP3_SCREEN.list);
      generateTitle(3);
      return;
    }
    setStep(step - 1);
    generateTitle(step - 1);
  };

  const generateTitle = (step: number) => {
    switch (step) {
      case 1:
        setTitle('プロジェクト新規作成ページ');
        break;
      case 2:
        setTitle('プロジェクト新規作成ページ');
        break;
      case 3:
        setTitle('リターン設定');
        break;
      case 4:
        setTitle('特定商取引に基づく表記');
        break;
      default:
        setTitle('プロジェクト新規作成ページ');
    }
  };

  const uploadFormImage = async (data: IProjectPayload): Promise<IProjectPayload> => {
    const updatedData = {
      ...data,
    };

    // Process each property in the object
    for (const key in updatedData) {
      if (updatedData.hasOwnProperty(key)) {
        let value = updatedData[key as keyof IProjectForm];
        if (Array.isArray(value)) {
          // Handle the 'return' array
          for (let i = 0; i < value.length; i++) {
            if (value[i].thumbnailUrl instanceof File) {
              const dataImg = await uploadPublicAndReplace(value[i].thumbnailUrl as File);
              value[i].thumbnailUrl = dataImg;
            }
          }
        } else if (value instanceof File) {
          const dataImg = await uploadAndReplace(value);
          (updatedData as any)[key as keyof Omit<IProjectForm, keyof IStepThreeForm>] = dataImg.fileName;
        }
      }
    }

    return updatedData;
  };

  const handleSaveDraft = async (data: IStepOneForm | IStepTwoForm | IStepThreeForm | IStepFourForm) => {
    // Still save data in case of api call fail.
    setAllStepsData({ ...allStepsData, ...data });
    try {
      openLoading();
      const draftData: IProjectPayload = {
        ...allStepsData,
        ...data,
        targetApAmount: allStepsData.targetApAmount ? String(allStepsData.targetApAmount) : '',
        today: new Date().toISOString(),
        status: EStatusProject.DRAFT,
        category: EProjectCategory.Movie,
      };
      const dataAfterUploadFile = await uploadFormImage(draftData);

      const payload = cleanObject(dataAfterUploadFile);
      if (!payload.title) {
        const ERROR_TITLE = 'プロジェクトタイトルは必須です。';
        toast.showError(ERROR_TITLE);
        return;
      }
      const response = await ProjectsListApi.saveDraftProject(payload);
      if (response) setIsDirtyForm(false);
    } catch (error: any) {
      const { errorMessages, errorMessage } = handleException(error);
      if (errorMessages || errorMessage) {
        setCreateError(errorMessages ?? errorMessage);
        toast.showError(errorMessage);
      }
    } finally {
      closeLoading();
    }
  };

  const handleSubmitForm = async (formDatas: IStepFourForm) => {
    // Still save data in case of api call fail.
    setAllStepsData({ ...allStepsData, ...formDatas });
    try {
      openLoading();
      const data: IProjectPayload = {
        ...allStepsData,
        ...formDatas,
        targetApAmount: allStepsData.targetApAmount ? String(allStepsData.targetApAmount) : '',
        today: new Date().toISOString(),
        status: EStatusProject.CREATED,
        category: EProjectCategory.Movie,
      };

      const dataAfterUploadFile = await uploadFormImage(data);
      const payload = cleanObject(dataAfterUploadFile);

      const response = await ProjectsListApi.createProject(payload);
      if (response) setIsDirtyForm(false);
    } catch (error: any) {
      const { errorMessages, errorMessage } = handleException(error);
      if (errorMessages || errorMessage) {
        setCreateError(errorMessages ?? errorMessage);
      }
    } finally {
      closeLoading();
    }
  };
  const stepFourDefaultValue = useMemo(() => {
    return {
      companyName: allStepsData.companyName,
      businessAddress: allStepsData.businessAddress,
      necessaryExpenses: allStepsData.necessaryExpenses,
      otherInformation: allStepsData.otherInformation,
      businessTel: allStepsData.businessTel,
    };
  }, [
    allStepsData.businessAddress,
    allStepsData.companyName,
    allStepsData.necessaryExpenses,
    allStepsData.otherInformation,
    allStepsData.businessTel,
  ]);

  const validateAndUpdate = (
    object: IStepOneForm | IStepTwoForm | IStepFourForm,
    schema: yup.ObjectSchema<IStepOneForm | IStepTwoForm | IStepFourForm>,
  ) => {
    try {
      schema.validateSync(object, { stripUnknown: true });
      // Validation successful, push step into completedSteps
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleFormChange = (data: IStepOneForm | IStepTwoForm | IStepThreeForm | IStepFourForm) => {
    var currentData = allStepsData;
    for (const key in data) {
      (currentData as any)[key] = (data as any)[key];
    }
    checkIsDirty();
    setAllStepsDataJson(JSON.stringify(currentData));
    setAllStepsData(currentData);
  };

  const checkIsDirty = () => {
    const isFormChange = Object.keys(dirtyFields).length > 0;
    if (isFormChange) setIsDirtyForm(true);
  };
  useEffect(() => {
    let data = JSON.parse(allStepsDataJson ?? '{}');
    const STEP_ONE = 1;
    const STEP_TWO = 2;
    const STEP_THREE = 3;
    const STEP_FOUR = 4;

    let _completedSteps: number[] = [];
    // Add 0 if validate fail.
    _completedSteps.push(validateAndUpdate(data, STEP_ONE_MODEL) ? STEP_ONE : 0);
    _completedSteps.push(validateAndUpdate(data, STEP_TWO_MODEL) ? STEP_TWO : 0);
    _completedSteps.push(data.returns !== undefined && data.returns.length > 0 ? STEP_THREE : 0);
    _completedSteps.push(validateAndUpdate(data, STEP_FOUR_MODEL) ? STEP_FOUR : 0);
    setcompletedSteps(_completedSteps.filter((item) => item !== 0));
  }, [allStepsDataJson]);

  useBeforeUnload(isDirtyForm !== undefined ? isDirtyForm : false);

  useEffect(() => {
    if (isDirtyForm === false) {
      navigate('/home');
    }
  }, [isDirtyForm, navigate]);

  return (
    <div className="px-20 py-16">
      <Heading1 text={title} backAction={onBackClick} />

      <div className="py-14">
        <StepBar currentStep={step} completedSteps={completedSteps} onChangeStep={handleChangeStep} />
      </div>

      {
        {
          1: (
            <StepOne
              allStepsData={allStepsData}
              onNextStep={handleNextStep}
              onSaveDraft={handleSaveDraft}
              onFormChange={handleFormChange}
              errors={errors}
              register={register}
              control={control}
              watch={watch}
            />
          ),
          2: (
            <StepTwo
              onNextStep={handleNextStep}
              onSaveDraft={handleSaveDraft}
              onFormChange={handleFormChange}
              errors={errors}
              control={control}
              watch={watch}
              getValues={getValues}
            />
          ),
          3: (
            <StepThree
              onNextStep={handleNextStep}
              onSaveDraft={handleSaveDraft}
              currentScreen={currentScreen}
              setCurrentScreen={setCurrentScreen}
              defaultValues={allStepsData.returns}
              onFormChange={handleFormChange}
              setValue={setValue}
            />
          ),
          4: (
            <StepFour
              onSubmitForm={handleSubmitForm}
              onSaveDraft={handleSaveDraft}
              defaultValues={stepFourDefaultValue}
              createError={createError}
              errors={errors}
              control={control}
              onFormChange={handleFormChange}
              watch={watch}
              handleSubmit={handleSubmit}
              getValues={getValues}
            />
          ),
        }[step]
      }
    </div>
  );
};

export default CreateProjectPage;
