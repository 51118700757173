import React, { useCallback, useEffect, useState } from "react";
import {
  IProject,
  IStepThreeForm,
  IStepThreeFormItem,
} from "@/module/projectsList/project.interface";
import ListScreen, { IStep3DataItem } from "./components/ListScreen";
import FormScreen from "./components/FormScreen";
import { UseFormSetValue } from "react-hook-form";
import { EStatusProject } from "@/module/projectsList/constants";

interface StepThreeProps {
  onNextStep: (step?: number, data?: IStepThreeForm) => void;
  onSaveDraft: (data: IStepThreeForm) => void;
  defaultValues?: IStepThreeFormItem[];
  onFormChange: (data: IStepThreeForm) => void;
  setValue: UseFormSetValue<IProject>;
  setCurrentScreen: React.Dispatch<React.SetStateAction<STEP3_SCREEN>>;
  currentScreen: STEP3_SCREEN;
  status?: EStatusProject;
}
export enum STEP3_SCREEN {
  list,
  form,
}

const getListScreenData = (formData: IStepThreeFormItem[] | undefined) => {
  if (formData)
    return formData.map((item) => ({
      id: item.id as string,
      img: item.thumbnailUrl,
      title: item.returnName,
      description: item.content,
      points: item.apAmount,
    }));
  return [];
};

const StepThree: React.FC<StepThreeProps> = ({
  onNextStep,
  onSaveDraft,
  defaultValues,
  onFormChange,
  setValue,
  currentScreen,
  setCurrentScreen,
  status
}) => {

  const [createdData, setCreatedData] = useState<IStepThreeFormItem[]>(
    defaultValues ?? []
  );

  const [selectedItem, setSelectedItem] = useState<IStepThreeFormItem>();

  const [listScreenData, setListScreenData] = useState<IStep3DataItem[]>(
    getListScreenData(defaultValues)
  );

  const handleSumbitForm = useCallback(
    (screen: STEP3_SCREEN, formDatas?: IStepThreeFormItem) => {
      if (formDatas) {
        let currData = createdData;
        // Find edited item
        let index = currData?.findIndex((item) => item.id === formDatas.id);
        // If existed => replace
        if (index !== -1) currData[index] = formDatas;
        // If not => create
        else currData?.unshift(formDatas);
        let _listScreenData = getListScreenData(currData);
        setListScreenData(_listScreenData);
        setCreatedData(currData);
        // Form will be summited straight to 4 step data, but will not move to next step.
        onNextStep(undefined, { returns: currData });
        setValue("returns", currData);
      }
      setCurrentScreen(screen);
    },
    [createdData, onNextStep]
  );

  const handleClickCreateButton = () => {
    setSelectedItem(undefined);
    setCurrentScreen(STEP3_SCREEN.form);
  };

  const handleItemClick = (id: string) => {
    let selectedItem = createdData.find((item) => item.id === id);
    setSelectedItem(selectedItem);
    setCurrentScreen(STEP3_SCREEN.form);
  };

  const handleNextStep = useCallback(() => {
    // Only move to next step, form has already been saved at handleSumbitForm
    onNextStep(4, { returns: createdData });
    setValue("returns", createdData);
  }, [onNextStep]);

  const handleSaveDraft = useCallback(() => {
    onSaveDraft({ returns: createdData });
  }, [createdData, onSaveDraft]);

  useEffect(() => {
    const data: IStepThreeForm = {
      returns: createdData,
    };

    onFormChange(data);
  }, [onFormChange, createdData]);

  return (
    <div>
      {currentScreen === STEP3_SCREEN.list ? (
        <ListScreen
          onCreateButtonClick={handleClickCreateButton}
          onNextStep={handleNextStep}
          onItemClick={handleItemClick}
          onSaveDraft={handleSaveDraft}
          data={listScreenData}
          status={status}
        />
      ) : (
        <FormScreen
          onSubmitForm={handleSumbitForm}
          defaultData={selectedItem}
          status={selectedItem?.isPublic}
        />
      )}
    </div>
  );
};

export default StepThree;
