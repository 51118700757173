import axios from "@/api/axios";
import s3Axios from "@/api/s3axios";
import { getImageMimeType } from "@/common/helpers";

export class UploadApi {
  static async UploadImage(fileName: string) {
    return axios.post(`file-upload/${fileName}`);
  }

  static async UploadImagePublic(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post("file-upload/public", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static async PutImage(file: File, fileUrl: string) {
    const mimetype = getImageMimeType(file?.name || "");

    return s3Axios.put(fileUrl, file, { headers: { "Content-Type": mimetype } });
  }

  static async GetImage(filePath: string) {
    return axios.get("file-upload", { params: { filePath } });
  }
}
