import React, { createContext, Dispatch, ReactNode, useContext, useState } from 'react';

type AffiliateContextData = {
  checkedUserId: string | null;
  affiliateLink: string | null;
  setCheckedUserId: Dispatch<React.SetStateAction<string | null>>;
  setAffiliateLink: Dispatch<React.SetStateAction<string | null>>;
};

type AffiliateProviderProps = {
  children: ReactNode;
};

const AffiliateContext = createContext<AffiliateContextData | undefined>(undefined);

export const useAffiliate = (): AffiliateContextData => {
  const context = useContext(AffiliateContext);
  if (context === undefined) {
    throw new Error('useAffiliate must be used within an AffiliateProvider');
  }
  return context;
};

export const AffiliateProvider = (props: AffiliateProviderProps) => {
  const { children } = props;
  const [checkedUserId, setCheckedUserId] = useState<string | null>(null);
  const [affiliateLink, setAffiliateLink] = useState<string | null>(null);

  const value = {
    checkedUserId,
    affiliateLink,
    setCheckedUserId,
    setAffiliateLink,
  };

  return <AffiliateContext.Provider value={value}>{children}</AffiliateContext.Provider>;
};
