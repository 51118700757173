import React from "react";
import './index.css';
import { IToast } from "../../interfaces/toast.interface";

const Toast: React.FC<IToast> = ({ closeToast, backgroundColor, content }) => {

    return (
        <div className={`toast-wrapper flex p-4 justify-between rounded items-center ${backgroundColor}`}>
            <p className="toast-message font-normal text-white text-lg">{content}</p>
            <img src="/assets/images/round-close.svg" onClick={closeToast} width={24} height={24} alt="close"/>
        </div>
    )
}

export default Toast