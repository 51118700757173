import { formatFullDate } from '@/common/helpers';
import { Checkbox } from '@/components/atom/Checkbox';
import { IColumns } from '@/module/support/type.interface';
import { IUsersResponse } from '@/module/users/response/users.interface';
import { useIssueAp } from '@/contexts/IssueApContext';
import { CheckedState } from '@radix-ui/react-checkbox';

interface TableUsersProps {
  columns: IColumns[];
  data: IUsersResponse[];
}

export const TableUsers = (props: TableUsersProps) => {
  const { columns, data } = props;
  const { checkedUserId, setCheckedUserId } = useIssueAp();

  const handleCheckedChange = (userId: string, isCheck: CheckedState) => {
    if (isCheck) {
      setCheckedUserId(userId);
    } else {
      setCheckedUserId(null);
    }
  };

  return (
    <div className="max-h-table border-separate overflow-clip flex flex-col border rounded-xl bg-white border-outline-200 px-4 py-9">
      <table className="table-fixed w-full">
        <thead className="sticky top-0">
          <tr>
            {columns.map((column) => (
              <th id={column.id} key={column.id} className={column.className}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div className="flex-1 overflow-y-auto">
        <table className="w-full table-fixed">
          <tbody>
            {data.length ? (
              <>
                {data.map((row, index) => (
                  <tr className="odd:bg-plate-300" key={index}>
                    <td className="text-left text-base font-medium truncate text-secondary-400 p-2 w-[11%]">
                      <Checkbox
                        checked={checkedUserId === row.id}
                        onCheckedChange={(isCheck) => handleCheckedChange(row.id, isCheck)}
                      />
                    </td>
                    <td className="text-left text-base font-medium truncate text-secondary-400 p-2 w-[30%]">
                      {row.nickName}
                    </td>
                    <td className="text-left text-base font-medium truncate text-secondary-400 p-2 w-[30%]">
                      {row.email}
                    </td>
                    <td className="text-left text-base font-medium text-secondary-400 p-2 w-[14%]">{row.userName}</td>
                    <td className="text-left text-base font-medium text-secondary-400 p-2 w-[15%]">
                      {formatFullDate(row.createdAt)}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td className="text-center text-2xl font-bold truncate text-secondary-400 py-10">データ無し</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
