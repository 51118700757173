import Heading1 from "@/components/common/Heading1";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UsersApi } from "@/module/users/api";
import { IUsersResponse } from "@/module/users/response/users.interface";
import { LabelDetail, LANGUAGE_CODE, SEX_CODE } from "@/module/users/constants";
import RowDetail from "../components/RowDetail";
import ConfirmPopup from "@/components/modals/ConfirmPopup";
import {
  displayData,
  formatFullDate,
} from "@/common/helpers";
import AppButton from "@/components/atom/Button/Button";
import { FilterUsersContext } from "@/contexts/FilterContext";
import { useLoading } from "@/hooks/useLoading";
import { handleException } from "@/common/exceptions/exception-handling";
import * as toast from '@/common/helpers/toast';

interface UserDetailPageProps {}

const popupConfig = {
  title: "以下のユーザーを削除しますか？",
  cancelText: "キャンセル",
  confirmText: "削除",
};

const UserDetailPage: React.FC<UserDetailPageProps> = () => {
  const navigate = useNavigate();
  const { userId } = useParams() || "";
  const [userInfor, setUserInfor] = useState<IUsersResponse>(
    {} as IUsersResponse
  );
  const { openLoading, closeLoading } = useLoading();

  const { search } = useContext(FilterUsersContext);

  useEffect(() => {
    if (userId) fetchUserData(userId);
  }, []);

  const fetchUserData = async (userId: string) => {
    try {
      openLoading();
      const response = await UsersApi.getUser(userId);
      const { data } = response;
      if (data) setUserInfor(data);
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const backAction = () => {
    navigate(`/users?search=${search}`);
  };
  const mapDataToDisplay = (data: IUsersResponse) => {
    return [
      {
        label: LabelDetail.NAME,
        value: `${data.nickName}`,
      },
      {
        label: LabelDetail.USER_NAME,
        value: `${data.userName}`,
      },
      {
        label: LabelDetail.DOB,
        value: `${formatFullDate(data.birthday)}`,
      },
      {
        label: LabelDetail.PHONE,
        value: `${displayData(data.tel)}`,
      },
      {
        label: LabelDetail.SEX,
        value: `${displayData(SEX_CODE[data.sex as keyof typeof SEX_CODE])}`,
      },
      {
        label: LabelDetail.LANGUAGE,
        value: `${displayData(LANGUAGE_CODE[data.language as keyof typeof LANGUAGE_CODE])}`,
      },
    ];
  };

  const deleteAction = async (adminUserId: string) => {
    try {
      openLoading();
      await UsersApi.deleteUser(adminUserId);
      navigate(`/users?search=${search}`);
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  return (
    <div className="px-20 py-16">
      <Heading1 text="ユーザー情報詳細" backAction={() => backAction()} />
      <div className="mt-10 py-4">
        <div className="flex flex-col gap-5">
          {mapDataToDisplay(userInfor).map((data, index) => (
            <div key={index}>
              <RowDetail data={data} key={index} />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-16">
          <ConfirmPopup
            popupConfig={{
              ...popupConfig,
              description: `ユーザー名：${userInfor.userName}`,
              onConfirm: () => deleteAction(userInfor.id),
            }}
          >
            <AppButton text="削除" size="lg" custome="bg-red-450" />
          </ConfirmPopup>
        </div>
      </div>
    </div>
  );
};

export default UserDetailPage;
