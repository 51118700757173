export interface ITabs {
  name: string;
  imgSrc: string;
  routerLink: string;
  isButton?: boolean;
  type?: TabType;
}

export const tabs = [
  { name: '管理画面', imgSrc: '/assets/images/house-outline.svg', routerLink: '/home' },
  { name: 'ユーザー管理', imgSrc: '/assets/images/outlet-user.svg', routerLink: '/users' },
  { name: '管理者管理', imgSrc: '/assets/images/admin-users-icon.svg', routerLink: '/admin-users' },
  { name: '通知管理', imgSrc: '/assets/images/notification.svg', routerLink: '/notifications' },
  { name: 'AP発行', imgSrc: '/assets/images/issue-ap-icon.svg', routerLink: '/issue-ap' },
  { name: 'アフィリエイト', imgSrc: '/assets/images/affiliate-icon.svg', routerLink: '/affiliate' },
];

export enum TabType {
  HOME = 'HOME',
  PREVIEW = 'PREVIEW',
  EDIT = 'EDIT',
  INTERVIEWS = 'INTERVIEWS',
  DASHBOARD = 'DASHBOARD',
  SUPPORTERS = 'SUPPORTERS',
  ISSUE_AP = 'ISSUE_AP',
}

export const detailTabs: ITabs[] = [
  {
    name: 'ダッシュボード',
    imgSrc: '/assets/images/dashboard-icon.svg',
    routerLink: '/dashboard',
    isButton: true,
    type: TabType.DASHBOARD,
  },
  {
    name: '支援者一覧',
    imgSrc: '/assets/images/interviews-list-icon.svg',
    routerLink: '/supporters',
    isButton: true,
    type: TabType.SUPPORTERS,
  },
  {
    name: '面談一覧',
    imgSrc: '/assets/images/admin-users-icon.svg',
    routerLink: '/interviews',
    isButton: false,
    type: TabType.INTERVIEWS,
  },
  {
    name: 'リターン送付',
    imgSrc: '/assets/images/send-return-icon.svg',
    routerLink: '/send-return',
    isButton: false,
    type: TabType.ISSUE_AP,
  },
];
