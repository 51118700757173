import React from 'react';
import { TextField as Text } from '@radix-ui/themes';
import { FieldError } from 'react-hook-form';
import { cn } from '@/lib/utils';

interface TextInputProps extends Text.RootProps {
  error?: FieldError | undefined;
  children?: React.ReactNode;
  className?: string;
}

const TextField = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  return (
    <Text.Root
      color={`${props.error?.message ? 'red' : 'gray'}`}
      {...props}
      ref={ref}
      className={cn(
        'ring-1 input-controller ring-gray-300 p-4 rounded text-sm',
        props.error && 'no-outline-important border-2 border-primary-500',
        props.className,
      )}
    >
      {props.children}
    </Text.Root>
  );
});

export default TextField;
