import { FULL_DATE_TIME_FORMAT } from "@/common/constants/common.constants";
import {
  displayData,
} from "@/common/helpers";
import { IColumns } from "@/module/adminUsers/type.interface";
import { ISupporterResponse } from "@/module/supportersList/response/supportersList.interface";
import { format } from "date-fns";
import React from "react";

interface TableSupportersProps {
  columns: IColumns[];
  data: ISupporterResponse[];
}

const TableSupporters: React.FC<TableSupportersProps> = ({ columns, data }) => {
  return (
    <div className="max-h-table border-separate overflow-clip flex flex-col border rounded-xl bg-white border-outline-200 px-4 py-9">
      <table className="table-fixed w-full">
        <thead className="sticky top-0">
          <tr>
            {columns.map((column) => (
              <th id={column.id} key={column.id} className={column.className}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div className="flex-1 overflow-y-auto">
        <table className="w-full table-fixed">
          {data.length ? (
            <tbody>
              <>
                {data.map((row, index) => (
                  <tr className="odd:bg-plate-300" key={index}>
                    <td className="text-left text-base font-medium truncate text-secondary-400 p-2 w-[25%]">
                      {displayData(row.returnName)}
                    </td>
                    <td className="text-left text-base font-medium truncate text-secondary-400 p-2 w-[25%]">
                      {displayData(row.userEmail)}
                    </td>
                    <td className="text-left text-base font-medium text-secondary-400 p-2 w-[12%]">
                      {displayData(row.userNickName)}
                    </td>
                    <td className="text-left text-base font-medium text-secondary-400 p-2 w-[10%]">
                      {displayData(
                        format(row.supportDate, FULL_DATE_TIME_FORMAT)
                      )}
                    </td>
                    <td className="text-center text-base font-medium text-neutral-850 p-2 w-[28%]">
                      {displayData(`${row.apAmount} AP`)}
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          ) : (
            <>
              <div className="text-center text-2xl font-bold truncate text-secondary-400 py-10">
                データ無し
              </div>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default TableSupporters;
