import { ERROR_MESSAGE, generateErrorLimitMsg, REGEX_ONLY_NUMBER } from '@/common/constants/common.constants';
import { removeHtmlTags } from '@/common/helpers';
import * as yup from 'yup';

export const FORM_SCHEMA = yup.object().shape({
  projectTitle: yup
    .string()
    .min(5, generateErrorLimitMsg(5, 50))
    .max(50, generateErrorLimitMsg(5, 50))
    .required(ERROR_MESSAGE.required),
  projectOverview: yup
    .string()
    .min(30, generateErrorLimitMsg(30, 200))
    .max(200, generateErrorLimitMsg(30, 200))
    .required(ERROR_MESSAGE.required),
  thumbnailImg: yup.mixed().required(ERROR_MESSAGE.required),
  targetAmount: yup
    .number()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .min(1, ERROR_MESSAGE.wrongFormat)
    .required(ERROR_MESSAGE.required),
  startDate: yup.date().typeError(ERROR_MESSAGE.wrongFormat).required(ERROR_MESSAGE.required),
  endDate: yup.date().typeError(ERROR_MESSAGE.wrongFormat).required(ERROR_MESSAGE.required),
  nameExecutor: yup
    .string()
    .min(3, generateErrorLimitMsg(3, 30))
    .max(30, generateErrorLimitMsg(3, 30))
    .required(ERROR_MESSAGE.required),
  profileExecutor: yup
    .string()
    .min(50, generateErrorLimitMsg(50, 300))
    .max(300, generateErrorLimitMsg(50, 300))
    .required(ERROR_MESSAGE.required),
  iconImgExecutor: yup.mixed().required(ERROR_MESSAGE.required),
  xLink: yup.string().url(ERROR_MESSAGE.wrongFormat),
  insLink: yup.string().url(ERROR_MESSAGE.wrongFormat),

  description: yup
    .string()
    .test('is-empty-html', ERROR_MESSAGE.required, (value) => removeHtmlTags(value).length !== 0)
    .test(
      'content-min-30-characters',
      `30${ERROR_MESSAGE.minCharacter}、1000${ERROR_MESSAGE.maxCharacter}`,
      (value) => removeHtmlTags(value).length >= 30 && removeHtmlTags(value).length <= 1000,
    )
    .required(ERROR_MESSAGE.required),

  returns: yup.array().required(ERROR_MESSAGE.required).min(1, ERROR_MESSAGE.required),

  companyName: yup.string().required(ERROR_MESSAGE.required),
  businessAddress: yup.string().required(ERROR_MESSAGE.required),
  necessaryExpenses: yup.string().required(ERROR_MESSAGE.required),
  otherInformation: yup.string().required(ERROR_MESSAGE.required).max(255, generateErrorLimitMsg(1, 255)),
  businessTel: yup
    .string()
    .required(ERROR_MESSAGE.required)
    .min(10, ERROR_MESSAGE.wrongFormat)
    .max(12, ERROR_MESSAGE.wrongFormat)
    .matches(REGEX_ONLY_NUMBER, ERROR_MESSAGE.wrongFormat),
});
