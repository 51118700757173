import React, { useEffect, useState } from "react";
import { ADMIN_USERS_COLUMNS } from "@/module/adminUsers/constants";
import AppButton from "@/components/atom/Button/Button";
import Heading1 from "@/components/common/Heading1";
import { AdminUsersApi } from "@/module/adminUsers/api";
import { IAdminUsersResponse } from "@/module/adminUsers/response/adminUsers.interface";
import { useNavigate } from "react-router-dom";
import TableAdminUsers from "./components/TableAdminUsers";
import { useLoading } from "@/hooks/useLoading";
import * as toast from "@/common/helpers/toast";
import { handleException } from "@/common/exceptions/exception-handling";
interface AdminUsersPageProps {}

const AdminUsersPage: React.FC<AdminUsersPageProps> = () => {
  const navigate = useNavigate();
  const [listUsers, setListUsers] = useState<IAdminUsersResponse[]>([]);
  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    try {
      openLoading();
      const response = await AdminUsersApi.getAllUsers();
      if (response?.data) {
        setListUsers(response?.data);
      }
    } catch (error: any) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };
  const backAction = () => {
    navigate(`/home`);
  };

  const editAction = (adminUserId: string) => {
    navigate(`/admin-users/edit/${adminUserId || ""}`);
  };

  const deleteAction = async (adminUserId: string) => {
    try {
      openLoading();
      await AdminUsersApi.deleteUser(adminUserId);
      fetchAllUsers();
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const registerAction = () => {
    navigate(`/admin-users/create`);
  };

  return (
    <div className="px-20 py-16">
      <Heading1 text="管理者一覧" backAction={backAction} />
      <div className="mt-10 mb-14">
        <div className="flex justify-end">
          <AppButton text="管理者登録" size="lg" onClick={registerAction} />
        </div>
      </div>
      <div className="">
        <TableAdminUsers
          columns={ADMIN_USERS_COLUMNS}
          editAction={editAction}
          deleteAction={deleteAction}
          data={listUsers}
        />
      </div>
    </div>
  );
};

export default AdminUsersPage;
