import React, { useCallback, useEffect } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormWatch,
} from "react-hook-form";
import AppFormField from "@/components/atom/AppFormField";
import AppButton from "@/components/atom/Button/Button";
import AppTextArea from "@/components/atom/TextArea/TextArea";
import TextField from "@/components/atom/TextField/TextField";
import {
  IProject,
  IStepFourForm,
} from "@/module/projectsList/project.interface";
import Alert from "@/components/atom/Alert";
import { EStatusProject } from "@/module/projectsList/constants";

interface IStepFourProps {
  defaultValues?: IStepFourForm;
  onSubmitForm: (formDatas: IStepFourForm) => void;
  onSaveDraft: (formDatas: IStepFourForm) => void;
  onFormChange: (data: IStepFourForm) => void;
  createError?: string | string[];
  errorMsg?: string;
  errors: FieldErrors<IProject>;
  control: Control<IProject>;
  watch: UseFormWatch<IProject>;
  getValues: UseFormGetValues<IProject>;
  handleSubmit: UseFormHandleSubmit<IProject, undefined>;
  status?: EStatusProject;
}

export interface IStepFourFormData {
  companyName: string;
  businessAddress: string;
  necessaryExpenses: string;
  otherInformation: string;
  businessTel: string;
}

const StepFour: React.FC<IStepFourProps> = (props) => {
  const {
    onSubmitForm,
    createError,
    onSaveDraft,
    errors,
    onFormChange,
    handleSubmit,
    watch,
    getValues,
    control,
    status,
  } = props;

  const onSubmit: SubmitHandler<IStepFourFormData> = useCallback(
    (data: IStepFourFormData) => {
      let mappingData: IStepFourForm = {
        companyName: data.companyName,
        businessAddress: data.businessAddress,
        necessaryExpenses: data.necessaryExpenses,
        otherInformation: data.otherInformation,
        businessTel: data.businessTel,
      };
      onSubmitForm(mappingData);
    },
    [onSubmitForm]
  );

  const handleSaveDraft = useCallback(() => {
    const _data: IStepFourForm = {
      companyName: getValues("companyName"),
      businessAddress: getValues("businessAddress"),
      necessaryExpenses: getValues("necessaryExpenses"),
      otherInformation: getValues("otherInformation"),
      businessTel: getValues("businessTel"),
    };
    onSaveDraft(_data);
  }, [getValues, onSaveDraft]);

  const onClickSaveDraft = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    handleSaveDraft();
  };

  const watchedValues = watch();

  useEffect(() => {
    const data: IStepFourForm = {
      companyName: watchedValues.companyName,
      businessAddress: watchedValues.businessAddress,
      necessaryExpenses: watchedValues.necessaryExpenses,
      otherInformation: watchedValues.otherInformation,
      businessTel: watchedValues.businessTel,
    };

    onFormChange(data);
  }, [onFormChange, watchedValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="space-y-14 flex flex-col items-start">
        <div className="flex flex-col gap-6 w-full mb-2">
          <AppFormField
            textLabel="販売事業者名"
            required={true}
            errorText={errors.companyName?.message}
          >
            <Controller
              control={control}
              name="companyName"
              render={({ field }) => (
                <TextField
                  error={errors.companyName}
                  placeholder="映画鑑賞チケット"
                  {...field}
                ></TextField>
              )}
            />
          </AppFormField>

          <AppFormField
            textLabel="事業者の所在地/住所"
            required={true}
            errorText={errors.businessAddress?.message}
          >
            <Controller
              control={control}
              name="businessAddress"
              render={({ field }) => (
                <TextField
                  error={errors.businessAddress}
                  placeholder="所在地/住所"
                  {...field}
                ></TextField>
              )}
            />
          </AppFormField>

          <AppFormField
            textLabel="事業者の電話番号"
            required={true}
            errorText={errors.businessTel?.message}
          >
            <Controller
              control={control}
              name="businessTel"
              render={({ field }) => (
                <TextField
                  placeholder="09012345678"
                  type="number"
                  error={errors.businessTel}
                  {...field}
                ></TextField>
              )}
            />
          </AppFormField>

          <AppFormField
            textLabel="対価以外に必要な費用"
            required={true}
            errorText={errors.necessaryExpenses?.message}
          >
            <Controller
              control={control}
              name="necessaryExpenses"
              render={({ field }) => (
                <TextField
                  error={errors.necessaryExpenses}
                  placeholder="対価以外に必要な費用を入力する"
                  {...field}
                ></TextField>
              )}
            />
          </AppFormField>

          <AppFormField
            textLabel="その他記載事項"
            required={true}
            errorText={errors.otherInformation?.message}
          >
            <Controller
              control={control}
              name="otherInformation"
              render={({ field }) => (
                <AppTextArea
                  custome="h-[125px]"
                  error={errors.otherInformation}
                  placeholder="その他記載事項を入力する"
                  {...field}
                />
              )}
            />
          </AppFormField>
        </div>
        {createError !== undefined || Object.keys(errors).length ? (
          <Alert
            variant="error"
            message={
              createError ||
              "入力に不備があります。必須項目を全て入力してください"
            }
          />
        ) : undefined}
      </div>
      <div className="flex gap-3 mt-[6rem]">
        {(status === EStatusProject.DRAFT || status === undefined) && (
          <AppButton
            text="下書きを保存"
            variant="outline"
            onClick={(event) => onClickSaveDraft(event)}
            size="lg"
          />
        )}
        <AppButton text="保存" size="lg" type="submit" />
      </div>
    </form>
  );
};

export default StepFour;
