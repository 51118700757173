import * as yup from 'yup';
import { SEND_RETURN_SCHEMA } from './support.validator';

export interface IColumns {
  id: string;
  label: string;
  className?: string;
}

export type SendReturnPayload = yup.InferType<typeof SEND_RETURN_SCHEMA>;

export const SEND_RETURN_QUANTITY_MIN = 1;
