import Heading1 from "@/components/common/Heading1";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NotificationForm from "../components/NotificationForm";
import { NotificationFormInputs } from "@/module/notifications/type.interface";
import { NOTIFICATION_SCHEMA } from "@/module/notifications/validations/notifications.validations";
import { NotificationsApi } from "@/module/notifications/api";
import { ENotificationTarget } from "@/module/notifications/constants";
import { handleException } from "@/common/exceptions/exception-handling";

interface CreateNotificationPageProps {}

const CreateNotificationPage: React.FC<CreateNotificationPageProps> = () => {
  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm<NotificationFormInputs>({
    resolver: yupResolver(NOTIFICATION_SCHEMA),
  });
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const onSubmit: SubmitHandler<NotificationFormInputs> = async (
    data: NotificationFormInputs
  ) => {
    try {
      const { category, title, content, startDate, endDate } = data;
      const body = {
        title,
        category,
        sentence: content,
        startDate: startDate.toISOString() || "",
        endDate: endDate.toISOString() || "",
        target: ENotificationTarget.USER,
      };
      const response = await NotificationsApi.createNotification(body);
      if (response) {
        navigate(`/notifications`);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      setErrorMsg(errorMessage);
    }
  };

  const backAction = () => {
    navigate(`/notifications`);
  };

  return (
    <div className="px-20 py-16">
      <Heading1
        text="通知登録画面"
        backAction={backAction}
        className={"mb-10"}
      />
      <NotificationForm
        register={register}
        trigger={trigger}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        errorMsg={errorMsg}
        control={control}
        textSubmit="登録"
      />
    </div>
  );
};

export default CreateNotificationPage;
