import { ERROR_MESSAGE } from "@/common/constants/common.constants";
import * as yup from "yup";

export const NOTIFICATION_SCHEMA = yup.object().shape({
    category: yup
      .number()
      .required(ERROR_MESSAGE.required),
    title: yup
      .string()
      .required(ERROR_MESSAGE.required)
      .max(500, ERROR_MESSAGE.wrongFormat),
    startDate: yup
      .date()
      .typeError(ERROR_MESSAGE.wrongFormat)
      .required(ERROR_MESSAGE.required)
      .test('is-before-end-date', ERROR_MESSAGE.startDate, function (value) {
        const { endDate } = this.parent;
        return value < endDate;
      }),
    endDate: yup
      .date()
      .typeError(ERROR_MESSAGE.wrongFormat)
      .required(ERROR_MESSAGE.required)
      .test('is-after-start-date', ERROR_MESSAGE.endDate, function (value) {
        const { startDate } = this.parent;
        return value > startDate;
      }),
    content: yup
      .string()
      .required(ERROR_MESSAGE.required)
  });