import React, { useEffect, useState } from "react";
import "./ProjectCard.css";
import ProgressRate from "./components/ProgressRate";
import LabelCard from "./components/LabelCard";
import AppButton from "@/components/atom/Button/Button";
import { IProjectsListResponse } from "@/module/projectsList/response/projectsList.interface";
import { EStatusProject } from "@/module/projectsList/constants";
import { format } from "date-fns";

interface ProjectCardProps {
  data: IProjectsListResponse;
  onPreview?: (projectId: string) => void;
  onDetail: (data: IProjectsListResponse) => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ data, onPreview, onDetail }) => {
  const {
    title,
    thumbnailUrl,
    summary,
    currentApAmount,
    amountProgress,
    countUser,
    remainingDays,
    status,
    startDate,
    id,
  } = data;
  const [isComingSoonProject, setIsComingSoonProject] = useState<boolean>(false);

  const checkComingSoonProject = () => {
    const isComingSoon =
      !!startDate && format(startDate, "yyyy/MM/dd") > format(new Date(), "yyyy/MM/dd");
    setIsComingSoonProject(isComingSoon);
  };

  const generateRemainingDays = () => {
    if (isComingSoonProject) {
      return <span className="text-[#6A6A6A] text-[11px]">公開前</span>;
    } else if (remainingDays > 0) {
      return (
        <>
          <span className="text-[#6A6A6A] text-[11px]">あと</span>
          <span className="text-accent-purple">{remainingDays}</span>
          <span className="text-[#6A6A6A] text-[11px]">日</span>
        </>
      );
    } else {
      return (
        <>
          <span className="text-[#6A6A6A] text-[11px]">終了</span>
        </>
      );
    }
  };

  useEffect(() => {
    checkComingSoonProject();
  }, []);

  return (
    <div>
      <div
        onClick={() => onDetail(data)}
        className={`w-[335px] h-[470px] hover:border-primary-500 mb-6 hover:shadow-project-card-hover cursor-pointer shadow-project-card bg-project-card rounded-2xl border border-outline-200 p-4`}
      >
        {status === EStatusProject.DRAFT && (
          <div className="mb-2">
            <LabelCard status={status} />
          </div>
        )}
        <h1 className="text-2xl leading-7 h-14 font-bold text-neutral-850 mb-2 line-clamp-2">
          {title}
        </h1>
        <img
          className="rounded-xl mb-2.5 h-[167px] w-full object-cover"
          src={thumbnailUrl ? thumbnailUrl : "/assets/images/no-image.png"}
          alt=""
        />

        <p className="text-[11px] h-8 font-medium text-secondary leading-4 line-clamp-2 mb-2.5">
          {summary}
        </p>
        {status !== EStatusProject.DRAFT && (
          <>
            <div className="py-1.5 px-2.5 mb-2.5 border border-outline-200 rounded-xl shadow-card-amount">
              <p className="text-[9.88px] text-accent-orange font-bold">
                支援金額
              </p>
              <div className="flex flex-wrap items-center justify-end gap-1">
                <div className="text-primary-500 font-bold text-2xl">
                  {currentApAmount.toLocaleString()}
                </div>
                <div className="text[#6A6A6A] font-bold text-sm height-fit">
                  AP
                </div>
              </div>
            </div>
            <ProgressRate
              amountProgress={parseInt(amountProgress?.replace("%", ""), 10)}
            />
            <div className="flex gap-2">
              <div className="flex-1">
                <p className="text-primary-400 text-[11px] font-medium w-fit h-fit">
                  支援者
                </p>
                <div className="flex h-8 py-1 px-2 items-center border border-outline-200 rounded-xl shadow-card-amount justify-between">
                  <img
                    className="w-4 h-4"
                    src="/assets/images/user-card-icon.svg"
                    alt=""
                  />
                  <div className="flex font-bold items-center">
                    <span className="text-accent-purple">{countUser}</span>
                    <span className="text-[#6A6A6A] text-[11px]">人</span>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <p className="text-primary-400 text-[11px] font-medium w-fit h-fit">
                  残日数
                </p>
                <div className="flex h-8 py-1 px-2 items-center border border-outline-200 rounded-xl shadow-card-amount justify-between">
                  <img
                    className="w-4 h-4"
                    src="/assets/images/calendar-card-icon.svg"
                    alt=""
                  />
                  <div className="flex font-bold items-center">
                    {generateRemainingDays()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {onPreview && status !== EStatusProject.DRAFT && (
        <AppButton
          onClick={() => onPreview(id)}
          text="プレビュー"
          size="sm"
          variant="outline"
          custome="px-5"
        />
      )}
    </div>
  );
};

export default ProjectCard;
