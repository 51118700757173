import { IResponseResult } from '@/interfaces/common.interface';
import axios from '../../api/axios';
import { IUsersBody } from './request/users.interface';
import { IUsersResponse } from './response/users.interface';
import { downloadCsv } from '@/common/helpers/file-download';

export class UsersApi {
    static getAllUsers(params?: IUsersBody): Promise<IResponseResult<IUsersResponse[]>> {
        return axios.get('/users', {params}).then((res) => res.data);
    }

    static getUser(userId: string): Promise<IResponseResult<IUsersResponse>> {
        return axios.get(`users/${userId}`).then((res) => res.data);
    }

    static deleteUser(userId: string) {
        return axios.delete(`users/${userId}`);
    }

    static async exportCsv(): Promise<void> {
        const response: any = await axios.post("users/export-all");
        await downloadCsv(response.data, "ユーザー一覧");
    }
}