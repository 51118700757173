export const BE_MESSAGE = {
  SYSTEM_ERROR: 'システムにエラーが発生しました。後でもう一度お試しください。',
  AMAUT0001: 'Basic authentication fail',
  AMAUT0002: '期限切れまたは正確でないリンクです。',
  AMAUT0003: 'Invalid password',
  AMADM0001: 'Admin user not found',
  AMADM0002: 'このメールアドレスは既に登録されています',
  AMMNF0001: 'Notification not found or removed',
};

export const REGEX_EMAIL = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const REGEX_PASSWORD = /^(?=.*[1-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[A-Za-z\d\W]{6,32}$/;
export const REGEX_ONLY_NUMBER = /^\d+$/;
export const ERROR_MESSAGE = {
  required: '必須項目です',
  wrongFormat: '正しい形式でご入力ください',
  password: '英大文字、英小文字、数字、記号(!@#$%+-=)をそれぞれ1文字以上用いて6~32文字でご入力ください',
  rePassword: '上記で入力したパスワードと同じパスワードを入力してください',
  startDate: '開始日時は終了日時より前にしてください',
  endDate: ' 終了日時は開始日時より後にしてください',
  minCharacter: '文字以上',
  maxCharacter: '文字以下でご入力ください。',
};
export const RELOAD_MESSAGE = '戻ると入力した内容は保存されないことになります。';

export const generateErrorLimitMsg = (min: number, max: number) => `${min}文字以上、${max}文字以内でご入力ください`;

export const FULL_DATE_TIME_FORMAT = 'yyyy/MM/dd HH:mm';
export const FULL_DATE_FORMAT = 'yyyy/MM/dd';

export const FULL_DATE_DASH_FORMAT = 'yyyy-MM-dd';
export const FULL_TIME_DASH_FORMAT = 'HH-mm-ss';

export const TOKEN_DECIMALS = {
  AP: 2,
};
