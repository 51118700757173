import React,  { createContext, useMemo, useState } from "react";
import { AuthContextData, AdminUserData, IAdminUser } from "../interfaces/auth-context.interface";

export const AuthContext = createContext<AuthContextData>({
    user: null,
    login: async () => {},
    logout: async () => {},
    getUserToken: () => '',
    setCurrentUser: () => null
});


type AuthProviderProps = {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
  const [user, setUser] = useState<IAdminUser | null>(null);

  const setUserLogin = (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  }

  // call this function when you want to authenticate the user
  const login = async (data: AdminUserData) => {
    setUserLogin(data.token, data.refreshToken);
  };

  // call this function to sign out logged in user
  const logout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  const getUserToken = () => {  
    return localStorage.getItem('accessToken');
  }

  const setCurrentUser = (data: IAdminUser) => {
    setUser(data);
  }

  const value = useMemo(
    (): AuthContextData => ({
      user,
      login,
      logout,
      getUserToken,
      setCurrentUser
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );

};
