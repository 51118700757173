import ConfirmPopup from '@/components/modals/ConfirmPopup';
import React from 'react';

interface IPublishPopupProps {
  openPopup: boolean;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm: Function;
  isEditStatus: boolean;
}

const PublishPopup = ({ openPopup, setOpenPopup, handleConfirm, isEditStatus }: IPublishPopupProps) => {
  const popupConfig = {
    title: isEditStatus ? '編集内容を公開しますか？' : 'プロジェクトを公開しますか？',
    confirmText: isEditStatus ? '更新' : '公開',
    cancelText: 'キャンセル',
    children: (
      <p className="mb-6 mt-4 text-base font-medium text-secondary-500">
        {isEditStatus
          ? '一度公開を更新すると、前のバージョンには戻せません。'
          : ' 一度公開するとプロジェクトを削除することができなくなります'}
      </p>
    ),
    onCancel: () => setOpenPopup(false),
    onConfirm: () => onClickConfirm(),
    open: openPopup,
  };

  const onClickConfirm = () => {
    setOpenPopup(false);
    handleConfirm();
  };

  return (
    <>
      <ConfirmPopup popupConfig={popupConfig} className="w-[343px] !p-4" />
    </>
  );
};

export default PublishPopup;
