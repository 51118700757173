import { handleException } from '@/common/exceptions/exception-handling';
import * as toast from '@/common/helpers/toast';
import AppFormField from '@/components/atom/AppFormField';
import AppButton from '@/components/atom/Button/Button';
import { ISelectData } from '@/components/atom/Datepicker/Datepicker';
import AppSelect from '@/components/atom/Select/Select';
import TextField from '@/components/atom/TextField/TextField';
import Heading1 from '@/components/common/Heading1';
import { useSendReturn } from '@/contexts/SendReturnContext';
import { useLoading } from '@/hooks/useLoading';
import { ProjectReturnApi } from '@/module/projectReturns/api';
import { TProjectReturn } from '@/module/projectReturns/type';
import { SupportApi } from '@/module/support/api';
import { SEND_RETURN_SCHEMA } from '@/module/support/support.validator';
import { SendReturnPayload } from '@/module/support/type.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

export const SendReturnForm = () => {
  const { checkedUserId } = useSendReturn();
  const { projectId } = useParams() ?? '';
  const { openLoading, closeLoading } = useLoading();
  const [projectReturns, setProjectReturns] = useState<TProjectReturn[]>([]);
  const [projectReturnSelected, setProjectReturnSelected] = useState<TProjectReturn | null>(null);
  const [projectReturnOptions, setProjectReturnOptions] = useState<ISelectData[]>([]);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm<SendReturnPayload>({
    resolver: yupResolver(SEND_RETURN_SCHEMA),
    defaultValues: {
      quantity: 1,
    },
  });

  const handleBack = () => {
    navigate(`/projects/${projectId}/send-return`);
  };

  const handleFetchProjectReturns = async () => {
    try {
      openLoading();
      const response = await ProjectReturnApi.getByProjectId(projectId ?? '');
      const projectReturns = response.data;
      const projectReturnOptions = projectReturns.map((projectReturn) => ({
        value: projectReturn.id,
        label: projectReturn.returnName,
      }));
      setProjectReturns(projectReturns);
      setProjectReturnOptions(projectReturnOptions);
    } catch (err) {
      const errorResult = handleException(err);
      toast.showError(errorResult.errorMessage ?? errorResult.errorMessages);
    } finally {
      closeLoading();
    }
  };

  const handleQuantityChange = (value: string) => {
    const numericValue = Number(value);
    setValue('quantity', numericValue);
    trigger('quantity');
  };

  const handleSendReturn = async (fields: SendReturnPayload) => {
    try {
      openLoading();
      const payload = { ...fields, userId: checkedUserId };
      await SupportApi.sendReturn(payload);
      toast.showSuccess('Success');
      navigate(`/projects/${projectId}/send-return`);
    } catch (err) {
      const errorResult = handleException(err);
      toast.showError(errorResult.errorMessage ?? errorResult.errorMessages);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    if (!checkedUserId || !projectId) {
      return handleBack();
    }
    handleFetchProjectReturns();
  }, [checkedUserId]);

  useEffect(() => {
    const projectReturnSelected = projectReturns.find((projectReturn) => projectReturn.id === getValues('returnId'));
    if (!projectReturnSelected) return;
    if (!projectReturnSelected?.isQuantitySelectable) {
      setValue('quantity', 1);
    }
    trigger('quantity');
    setProjectReturnSelected(projectReturnSelected);
  }, [watch('returnId')]);

  return (
    <div className="px-20 py-16 space-y-[90px]">
      <Heading1 text="リターン送付" backAction={handleBack} />
      <form className="flex flex-col items-center space-y-[90px]" onSubmit={handleSubmit(handleSendReturn)}>
        <div className="flex flex-col w-full space-y-8">
          <AppFormField textLabel="リターン" required={true} errorText={errors.returnId?.message}>
            <Controller
              name="returnId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AppSelect
                  error={errors.returnId}
                  onChange={(val) => onChange(val.value)}
                  value={value}
                  items={projectReturnOptions}
                  placeholder="映画鑑賞チケット"
                />
              )}
            />
          </AppFormField>
          <AppFormField textLabel="個数" required={true} errorText={errors.quantity?.message}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  placeholder="1"
                  error={errors.quantity}
                  className="[&>input]:text-right [&>input]:px-5 [&>input]:text-base"
                  {...field}
                  disabled={!projectReturnSelected?.isQuantitySelectable}
                  value={field.value || ''}
                  onChange={(e) => handleQuantityChange(e.target.value)}
                />
              )}
            />
          </AppFormField>
        </div>
        <AppButton text="送付" size="lg" />
      </form>
    </div>
  );
};
