import AppButton from "@/components/atom/Button/Button";
import Heading1 from "@/components/common/Heading1";
import { EStatusProject } from "@/module/projectsList/constants";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PublishPopup from "./popup/PublishPopup";
import PublishedPopup from "./popup/PublishedPopup";
import DeletePopup from "./popup/DeletePopup";
import { ProjectsListApi } from "@/module/projectsList/api";
import { IProjectDetailResponseData } from "@/module/projectsList/response/projectsList.interface";
import { handleException } from "@/common/exceptions/exception-handling";
import * as toast from "@/common/helpers/toast";
import { useLoading } from "@/hooks/useLoading";

interface DetailHeaderProps {
  onEdit?: () => void;
  detailData: IProjectDetailResponseData;
  setDetailData: React.Dispatch<
    React.SetStateAction<IProjectDetailResponseData>
  >;
}

const DetailHeader: React.FC<DetailHeaderProps> = ({
  detailData,
  onEdit,
  setDetailData,
}) => {
  const { status, id } = detailData;
  const navigate = useNavigate();
  const onBack = () => {
    navigate("/home");
  };
  const [publishPopup, setPuplishPopup] = useState(false);
  const [publishedPopup, setPuplishedPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const { openLoading, closeLoading } = useLoading();

  const confirmPublishPopup = async () => {
    try {
      openLoading();
      await ProjectsListApi.publishProject(id).then(() => {
        fetchDataDetail(id);
      });
    } catch (error) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      setPuplishPopup(false);
      closeLoading();
    }
  };

  const fetchDataDetail = async (projectId: string) => {
    try {
      const response = await ProjectsListApi.getProject(projectId);
      const { data } = response;
      if (data) {
        setDetailData(data);
        setPuplishPopup(false);
        setPuplishedPopup(true);
        setIsEditStatus(false);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    }
  };

  const handleConfrimDeletePopup = async () => {
    try {
      openLoading();
      await ProjectsListApi.deleteProject(id).then(() => {
        toast.showSuccess("プロジェクトが正常に削除されました");
        navigate("/home");
      });
    } catch (error) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      setDeletePopup(false);
      closeLoading();
    }
  };

  return (
    <div className="mb-4 w-full">
      <div className="px-4 py-2 bg-plate-100 flex gap-3 justify-end">
        <AppButton
          text="プレビュー"
          variant="outline"
          size="sm"
          custome="bg-white px-4"
          onClick={() => navigate(`/projects/${id}/preview?from=dashboard`)}
        ></AppButton>
        {status === EStatusProject.CREATED && (
          <AppButton
            text="公開"
            variant="outline"
            size="sm"
            custome="bg-white px-9"
            onClick={() => setPuplishPopup(true)}
          ></AppButton>
        )}
        {status === EStatusProject.EDITING && (
          <AppButton
            text="編集を公開"
            variant="outline"
            size="sm"
            custome="bg-white px-6"
            onClick={() => {
              setPuplishPopup(true);
              setIsEditStatus(true);
            }}
          ></AppButton>
        )}
      </div>
      <div className="py-8 mx-auto mb:w-[91.5%] tb:w-[91.5%] w-[800px]">
        <div className="flex justify-between items-center">
          <Heading1 text={"ダッシュボード"} backAction={onBack} />
          <div className="flex gap-2">
            {status === EStatusProject.CREATED && (
              <AppButton
                text="削除"
                variant="outline"
                onClick={() => setDeletePopup(true)}
              ></AppButton>
            )}
            <AppButton
              onClick={onEdit}
              text="編集"
              custome="bg-red-450"
            ></AppButton>
          </div>
        </div>
      </div>
      <PublishPopup
        openPopup={publishPopup}
        setOpenPopup={setPuplishPopup}
        handleConfirm={confirmPublishPopup}
        isEditStatus={isEditStatus}
      />
      <PublishedPopup
        projectID={id}
        openPopup={publishedPopup}
        handleConfirm={() => setPuplishedPopup(false)}
      />
      <DeletePopup
        openPopup={deletePopup}
        setOpenPopup={setDeletePopup}
        handleConfirm={handleConfrimDeletePopup}
      />
    </div>
  );
};

export default DetailHeader;
