import React from "react";
import FormLabel from "../FormLabel/FormLabel";

interface AppFormFieldPropsProps {
  children: React.ReactNode;
  required?: boolean;
  textLabel?: string;
  hint?: string;
  errorText?: string;
}

const AppFormField: React.FC<AppFormFieldPropsProps> = ({
  children,
  textLabel,
  required,
  hint,
  errorText,
}) => {
  return (
    <div>
      {textLabel && (
        <FormLabel
          className="block text-sm font-medium text-gray-700"
          text={textLabel ? textLabel : ""}
          required={required}
        ></FormLabel>
      )}
      {children}
      {hint && (
        <span className="text-secondary-500 text-xs">
          {hint}
        </span>
      )}
      {errorText && (
        <span className="text-red-500 text-xs">{errorText}</span>
      )}
    </div>
  );
};

export default AppFormField;
