import React, { useState } from "react";
import { Flex } from "@radix-ui/themes";
import { useForm, SubmitHandler } from "react-hook-form"
import AppFormField from "@/components/atom/AppFormField";
import AppButton from "@/components/atom/Button/Button";
import PasswordField from "@/components/atom/PasswordField/PasswordField";
import { AuthApi } from "@/module/auth/api";
import { useNavigate } from "react-router-dom";
import TextField from "@/components/atom/TextField/TextField";
import { useLoading } from "@/hooks/useLoading";
import { handleException } from "@/common/exceptions/exception-handling";


type BasicAuth = {
}

type BasicAuthFormInputs = {
    username: string
    password: string
}
const BasicAuth:  React.FC<BasicAuth> = ({}) => {
    const navigate = useNavigate();
    const [isErrorLogin, setIsErrorLogin] = useState(false);
    const { openLoading, closeLoading } = useLoading();
    
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm<BasicAuthFormInputs>()
      const onSubmit: SubmitHandler<BasicAuthFormInputs> = async (data: BasicAuthFormInputs) => {
        try {
            openLoading();
            const response = await AuthApi.basicAuth(data.username, data.password);
            if (response?.data?.token) {
                navigate(`/auth/login?token=${response.data.token}`);
            }
        } catch (error) {
            const { errorMessage } = handleException(error);
            if (errorMessage) {
                setIsErrorLogin(true);
            }
        } finally {
            closeLoading();
        }
      }
    
    return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex direction={'column'} gap={'60px'}>
                    <Flex className="login-content" direction={'column'} gap={'40px'}>
                        <Flex className="login-introduce text-left" direction={'column'} gap={'16px'}>
                            <p className="text-neutral-850 text-4xl font-bold">Basic認証</p>
                            <p className="text-secondary-500 font-medium text-xl leading-6">管理者に配布済みのIDとパスワードを入力してください。</p>
                        </Flex>
                        <Flex className="login-field text-left" direction={'column'} gap={'22px'}>
                                <Flex direction={'column'} gap={'4px'}>
                                    <AppFormField
                                        textLabel="ID"
                                        required={true}
                                        errorText={errors.username?.message}
                                    >
                                        <TextField {...register("username", {required : "必須項目です"})}
                                            placeholder="arigatomax@gmail.com" error={errors.username} />
                                    </AppFormField>
                                </Flex>
                                <Flex direction={'column'} gap={'4px'}>
                                    <AppFormField
                                        textLabel="パスワード"
                                        required={true}
                                        errorText={errors.password?.message}
                                    >
                                        <PasswordField {...register("password", {required : "必須項目です"})} error={errors.password} />
                                    </AppFormField>
                                </Flex>
                        </Flex>
                    </Flex>
                    {isErrorLogin ? <p className="error-login text-center text-sm text-red-450 font-medium">IDまたはパスワードが違います</p> : null}
                    <div className="m-auto">
                        <AppButton size="lg" text="次へ" type="submit"/>
                    </div>
                </Flex>
            </form>
    )
}

export default BasicAuth