import * as Progress from "@radix-ui/react-progress";

interface ProgressRateProps {
  amountProgress: number;
  customeProgress?: string;
  customeText?: string;
}

const ProgressRate: React.FC<ProgressRateProps> = ({
  amountProgress,
  customeProgress = 'h-[7px]',
  customeText = 'text-[11px]',
}) => {
  return (
    <div>
      <div className="w-full mb-2.5">
        <div className="flex gap-1 mb-0.5">
          <p className={`text-primary-400 ${customeText} font-medium w-fit h-fit`}>
            達成率
          </p>
          <div className={`text-primary-400 ${customeText} font-medium w-fit h-fit`}>
            {amountProgress}%
          </div>
        </div>
        <Progress.Root
          className={`relative overflow-hidden bg-[#859BA0] rounded-full w-full ${customeProgress}`}
          style={{
            transform: "translateZ(0)",
          }}
          max={1000000}
          value={amountProgress}
        >
          <Progress.Indicator
            className="bg-gradient-to-r from-[#E1345C] to-[#FF7CC3] rounded-full w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
            style={{
              transform: `translateX(-${100 - amountProgress}%)`,
            }}
          />
        </Progress.Root>
      </div>

    </div>
  );
};

export default ProgressRate;
