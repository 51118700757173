import React, { createContext, useMemo, useState } from "react";
import { IFilterUsersContextData } from "@/interfaces/filter-context.interface";

export const FilterUsersContext = createContext<IFilterUsersContextData>({
  search: '',
  setCurrentSearch: () => undefined,
});

type FilterUsersProviderProps = {
  children: React.ReactNode;
};

export const FilterProvider: React.FC<FilterUsersProviderProps> = ({
  children,
}) => {
  const [search, setSearch] = useState<string>('');

  const setCurrentSearch = (search: string) => {
    setSearch(search);
  };

  const value = useMemo(
    (): IFilterUsersContextData => ({
      search,
      setCurrentSearch,
    }),
    [search]
  );

  return (
    <FilterUsersContext.Provider value={value}>
      {children}
    </FilterUsersContext.Provider>
  );
};
