import React, { memo, useMemo } from "react";
import { SwitchProps, Root, Thumb } from "@radix-ui/react-switch";
import "./index.css";
interface ISwitcher extends SwitchProps {
  rootClassname?: string;
  thumbClassname?: string;
}

const Switcher: React.FC<ISwitcher> = (props) => {
  const { rootClassname, thumbClassname, ...rest } = props;

  const _rootClassname = useMemo(() => {
    const _default = "defaultSwitchRoot";
    return rootClassname ? `${_default} ${rootClassname}` : _default;
  }, [rootClassname]);

  const _thumbClassname = useMemo(() => {
    const _default = "defaultSwitchThumb";
    return thumbClassname ? `${_default} ${thumbClassname}` : _default;
  }, [thumbClassname]);

  return (
    <>
      <Root {...rest} className={_rootClassname}>
        <Thumb className={_thumbClassname} />
      </Root>
    </>
  );
};
export default memo(Switcher);
