import ConfirmPopup from "@/components/modals/ConfirmPopup";
import { IProjectDetailResponseData } from "@/module/projectsList/response/projectsList.interface";
import RowData from "@/pages/ProjectsList/components/RowData/RowData";
import { displayData, formatPhoneNumber } from "@/common/helpers";

interface ICommercialTransactionsPopup {
  detailData: IProjectDetailResponseData;
  openPopup: boolean;
  handleConfirm: () => void;
}

const CommercialTransactionsPopup = ({
  detailData,
  openPopup,
  handleConfirm,
}: ICommercialTransactionsPopup) => {
  const {
    companyName,
    businessAddress,
    necessaryExpenses,
    otherInformation,
    businessTel,
  } = detailData;
  const popupConfig = {
    confirmText: "閉じる",
    children: (
      <div>
        <div className="text-center text-2xl cursor-default text-neutral-850 font-bold">
          特定商取引法に基づく表記
        </div>
        <div className="my-10 gap-7 flex flex-col">
          <RowData label="販売事業者名" data={displayData(companyName)} />
          <RowData
            label="事業者の住所/所在地"
            data={displayData(businessAddress)}
          />
          <RowData
            label="事業者の電話番号"
            data={displayData(formatPhoneNumber(businessTel))}
          />
          <RowData
            label="対価以外に必要な費用"
            data={displayData(necessaryExpenses)}
          />
          <RowData
            label="その他記載事項"
            data={displayData(otherInformation)}
          />
        </div>
      </div>
    ),
    onConfirm: () => handleConfirm(),
    open: openPopup,
  };

  return (
    <>
      <ConfirmPopup
        popupConfig={popupConfig}
        className="w-[625px] !px-4 !pb-4 pt-0 overflow-auto"
      />
    </>
  );
};

export default CommercialTransactionsPopup;
