import DetailContent from "./components/DetailContent";
import { handleException } from "@/common/exceptions/exception-handling";
import { useLoading } from "@/hooks/useLoading";
import { ProjectsListApi } from "@/module/projectsList/api";
import { DEFAULT_DETAIL_DATA } from "@/module/projectsList/constants";
import { IProjectDetailResponseData } from "@/module/projectsList/response/projectsList.interface";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as toast from "@/common/helpers/toast";
import DetailHeader from "./components/DetailHeader";

interface ProjectDetailPageProps {}

const ProjectDetailPage: React.FC<ProjectDetailPageProps> = () => {
  const { projectId } = useParams() || "";
  const navigate = useNavigate();
  const [detailData, setDetailData] =
    useState<IProjectDetailResponseData>(DEFAULT_DETAIL_DATA);

  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    if (projectId) {
      fetchDataDetail(projectId);
    }
  }, []);

  const fetchDataDetail = async (projectId: string) => {
    try {
      openLoading();
      const response = await ProjectsListApi.getProject(projectId);
      const { data } = response;
      if (data) {
        setDetailData(data);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const onEdit = () => {
    navigate(`/projects/${projectId}/edit`);
  };

  return (
    <div className="flex flex-col items-center">
      <DetailHeader
        onEdit={onEdit}
        detailData={detailData}
        setDetailData={setDetailData}
      />
      <DetailContent detailData={detailData} projectId={projectId || ""} />
    </div>
  );
};

export default ProjectDetailPage;
