import ConfirmPopup from '@/components/modals/ConfirmPopup';
import React from 'react';

interface IDeletePopupProps {
  openPopup: boolean;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm: Function;
}

const DeletePopup = ({ openPopup, setOpenPopup, handleConfirm }: IDeletePopupProps) => {
  const popupConfig = {
    title: 'プロジェクトを削除しますか？',
    confirmText: '削除',
    cancelText: 'キャンセル',
    children: (
      <p className="mb-6 mt-4 text-base font-medium text-secondary-500">
        プロジェクトを削除すると、復元ができなくなります。削除しますか？
      </p>
    ),
    onCancel: () => setOpenPopup(false),
    onConfirm: () => onClickConfirm(),
    open: openPopup,
  };

  const onClickConfirm = () => {
    setOpenPopup(false);
    handleConfirm();
  };

  return (
    <>
      <ConfirmPopup popupConfig={popupConfig} className="w-[343px] !p-4" />
    </>
  );
};

export default DeletePopup;
