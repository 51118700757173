import App from "@/App";
import AppFormField from "@/components/atom/AppFormField";
import AppButton from "@/components/atom/Button/Button";
import AppDatePicker from "@/components/atom/Datepicker/Datepicker";
import AppSelect from "@/components/atom/Select/Select";
import AppTextArea from "@/components/atom/TextArea/TextArea";
import TextField from "@/components/atom/TextField/TextField";
import {
  ENotificationCategory,
  NOTIFICATION_CATEGORY_VALUES,
} from "@/module/notifications/constants";
import { NotificationFormInputs } from "@/module/notifications/type.interface";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";

interface NotificationFormProps {
  onSubmit: SubmitHandler<NotificationFormInputs>;
  errorMsg?: string;
  handleSubmit: UseFormHandleSubmit<NotificationFormInputs, undefined>;
  register: UseFormRegister<NotificationFormInputs>;
  errors: FieldErrors<NotificationFormInputs>;
  textSubmit: string;
  control: Control<NotificationFormInputs, any>;
  defaultValue?: NotificationFormInputs | null;
  trigger: UseFormTrigger<NotificationFormInputs>;
}

const NotificationForm: React.FC<NotificationFormProps> = ({
  onSubmit,
  errorMsg,
  handleSubmit,
  register,
  errors,
  control,
  trigger,
  defaultValue,
  textSubmit,
}) => {
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setDateStart(defaultValue.startDate);
      setDateEnd(defaultValue.endDate);
    }
  }, [defaultValue]);

  // Watch for changes in startDate and endDate
  const startDate = useWatch({ control, name: "startDate" });
  const endDate = useWatch({ control, name: "endDate" });
  // Use useEffect to trigger validation whenever dates change
  useEffect(() => {
    if (startDate && endDate && Object.keys(errors).length > 0) {
      trigger(["startDate", "endDate"]);
    }
  }, [startDate, endDate, trigger]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-14 flex flex-col items-center"
    >
      <div className="flex flex-col gap-6 w-full mb-2">
        <AppFormField
          textLabel="カテゴリ"
          required={true}
          errorText={errors.category?.message}
        >
          <Controller
            name="category"
            control={control}
            rules={{ required: true }}
            defaultValue={ENotificationCategory.MAINTENANCE}
            render={({ field: { onChange, value } }) => (
              <AppSelect
                error={errors.category}
                onChange={(val) => onChange(val.value)}
                value={value}
                items={NOTIFICATION_CATEGORY_VALUES}
                defaultValue={ENotificationCategory.MAINTENANCE}
              />
            )}
          />
        </AppFormField>

        <AppFormField
          textLabel="タイトル"
          required={true}
          errorText={errors.title?.message}
        >
          <TextField
            error={errors.title}
            placeholder="定期メンテナンスの実施"
            {...register("title")}
          ></TextField>
        </AppFormField>

        <AppFormField
          textLabel="開始日時"
          required={true}
          errorText={errors.startDate?.message}
        >
          <Controller
            control={control}
            name="startDate"
            render={({ field: { onChange, value, ref } }) => (
              <AppDatePicker
                value={value}
                showTimeInput={true}
                error={errors.startDate}
                minDate={new Date()}
                maxDate={dateEnd}
                placeholder={format(new Date(), "yyyy/MM/dd HH:mm")}
                ref={ref}
                onChange={(date) => {
                  onChange(date);
                  setDateStart(date);
                }}
              />
            )}
          />
        </AppFormField>

        <AppFormField
          textLabel="終了日時"
          required={true}
          errorText={errors.endDate?.message}
        >
          <Controller
            control={control}
            name="endDate"
            render={({ field: { onChange, value, ref } }) => (
              <AppDatePicker
                minDate={dateStart}
                error={errors.endDate}
                showTimeInput={true}
                value={value}
                ref={ref}
                placeholder={format(new Date(), "yyyy/MM/dd HH:mm")}
                onChange={(date) => {
                  onChange(date);
                  setDateEnd(date);
                }}
              />
            )}
          />
        </AppFormField>

        <AppFormField
          textLabel="本文"
          required={true}
          errorText={errors.content?.message}
        >
          <Controller
            control={control}
            name="content"
            render={({ field }) => (
              <AppTextArea
                placeholder="本文入力する"
                error={errors.content}
                {...field}
              />
            )}
          />
        </AppFormField>
      </div>
      {errorMsg && <span className="text-red-500 text-xs">{errorMsg}</span>}

      <AppButton text={textSubmit} size="lg" type="submit" />
    </form>
  );
};

export default NotificationForm;
