import { ISelectData } from "@/components/atom/Select/Select";
import { IColumns } from "./type.interface";

export enum ColumnNotificationId {
    CATEGORY = "category",
    TITLE = "title",
    START_DATE = "start-date",
    END_DATE = "end-date",
    EDIT = "edit",
    DELETE = "delete",
  }
  
export const NOTIFICATIONS_COLUMNS: IColumns[] = [
    {
      id: ColumnNotificationId.CATEGORY,
      label: "カテゴリ",
      className: "text-left p-2.5 w-[26%]",
    },
    {
      id: ColumnNotificationId.TITLE,
      label: "タイトル",
      className: "text-left p-2.5 w-[26%]",
    },
    {
      id: ColumnNotificationId.START_DATE,
      label: "開始日時",
      className: "text-left p-2.5 w-[19%]",
    },
    {
      id: ColumnNotificationId.END_DATE,
      label: "終了日時",
      className: "text-left p-2.5 w-[19%]",
    },
    {
      id: ColumnNotificationId.EDIT,
      label: "編集",
      className: "text-left p-2.5 w-[15%]",
    },
    {
      id: ColumnNotificationId.DELETE,
      label: "削除",
      className: "text-left p-2.5 w-[15%]",
    },
  ];

  export enum ENotificationCategory {
    NEWS = 1,
    MAINTENANCE = 2,
    SUPPORTING = 3,
}

export enum ENotificationTarget {
    ALL = 0,
    USER = 1,
    FLIMAKER = 2,
    ADMIN = 3,
}

export const NOTIFICATION_CATEGORY = {
  [ENotificationCategory.NEWS]: "ニュース",
  [ENotificationCategory.MAINTENANCE]: "メンテナンス",
  [ENotificationCategory.SUPPORTING]: "サポート",
}

export const NOTIFICATION_CATEGORY_VALUES: ISelectData[] = [
  { value: ENotificationCategory.NEWS, label: "ニュース" },
  { value: ENotificationCategory.MAINTENANCE, label: "メンテナンス" },
  { value: ENotificationCategory.SUPPORTING, label: "サポート" },
]