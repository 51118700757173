import React, { useEffect, useState } from "react";
import { Flex } from "@radix-ui/themes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { REGEX_EMAIL } from "@/common/constants/common.constants";
import AppFormField from "@/components/atom/AppFormField";
import AppButton from "@/components/atom/Button/Button";
import PasswordField from "@/components/atom/PasswordField/PasswordField";
import { AuthApi } from "@/module/auth/api";
import TextField from "@/components/atom/TextField/TextField";
import { useLoading } from "@/hooks/useLoading";
import { handleException } from "@/common/exceptions/exception-handling";
import * as toast from "@/common/helpers/toast";

type LoginPageProps = {};

type LoginFormInputs = {
  email: string;
  password: string;
};

const LoginPage: React.FC<LoginPageProps> = ({}) => {
  const navigate = useNavigate();
  const [isErrorLogin, setIsErrorLogin] = useState(false);
  const [searchParams] = useSearchParams();
  const { openLoading, closeLoading } = useLoading();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>();
  const onSubmit: SubmitHandler<LoginFormInputs> = async (
    data: LoginFormInputs
  ) => {
    try {
      openLoading();
      const response = await AuthApi.login(
        data.email,
        data.password,
        searchParams.get("token") || ""
      );
      if (response?.data?.token) {
        navigate(
          `/auth/2fa-verification?email=${data.email}&token=${response.data.token}`
        );
      }
    } catch (error) {
      setIsErrorLogin(true);
      console.log(error);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await AuthApi.verifyBasicAuthen(searchParams.get("token") || "");
      } catch (error) {
        const { errorMessage } = handleException(error);
        errorMessage && toast.showError(errorMessage);
      }
    };
    verifyToken();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        direction={"column"}
        gap={"60px"}
        justify={"center"}
        align={"center"}
      >
        <Flex
          className="login-content w-full"
          direction={"column"}
          gap={"40px"}
        >
          <Flex
            className="login-introduce text-left"
            direction={"column"}
            gap={"16px"}
          >
            <p className="text-neutral-850 text-4xl font-bold">ログイン</p>
            <Flex direction={"column"} gap={"8px"}>
              <p className="text-secondary-500 font-medium text-xl leading-6">
                ログイン情報を入力してください。
              </p>
              <p className="text-secondary-500 font-medium text-sm leading-4">
                お忘れの場合は管理者へ問い合わせしてください
              </p>
            </Flex>
          </Flex>
          <Flex
            className="login-field text-left"
            direction={"column"}
            gap={"22px"}
          >
            <Flex direction={"column"} gap={"4px"}>
              <AppFormField
                textLabel="メールアドレス"
                required={true}
                errorText={errors.email?.message}
              >
                <TextField
                  {...register("email", {
                    required: "必須項目です",
                    pattern: {
                      value: REGEX_EMAIL,
                      message: "正しい形式でご入力ください",
                    },
                  })}
                  placeholder="arigatomax@gmail.com"
                  error={errors.email}
                />
              </AppFormField>
            </Flex>
            <Flex direction={"column"} gap={"4px"}>
              <AppFormField
                textLabel="パスワード"
                required={true}
                errorText={errors.password?.message}
              >
                <PasswordField
                  {...register("password", { required: "必須項目です" })}
                  error={errors.password}
                />
              </AppFormField>
            </Flex>
          </Flex>
        </Flex>
        {isErrorLogin ? (
          <p className="error-login text-center text-sm text-red-450 font-medium">
            メールアドレスまたはパスワードが違います
          </p>
        ) : null}
        <AppButton size="lg" text="ログイン" type="submit" />
      </Flex>
    </form>
  );
};

export default LoginPage;
