import React, { useState } from "react";
import { TextField } from "@radix-ui/themes";
import { FieldError } from "react-hook-form";
import './PasswordField.css';
interface TextInputProps extends TextField.RootProps {
    error?: FieldError | undefined;
    placeholder?: string;
}

type PasswordFieldProps = {
    username: string;
    password: string;
}

const PasswordField = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <TextField.Root
            {...props} 
            ref={ref}
            color={`${props.error?.message ? 'red' : 'gray'}`}
            type={showPassword ? 'text' : 'password'}
            placeholder={props.placeholder ? props.placeholder : "パスワード入力..."}
            className={`ring-1 input-controller ring-gray-300 p-3 rounded text-sm font-medium ${props.error ? 'no-outline-important border-2 border-primary-500' : ''}`}
        >
            <TextField.Slot className="input-icon text-sm" side={'right'} onClick={handleShowPassword}>
                {showPassword ? <img src="/assets/images/close-eye.svg" alt="show" /> : <img src="/assets/images/open-eye.svg" alt="show" />}
            </TextField.Slot>
        </TextField.Root>
    )
});

export default PasswordField;