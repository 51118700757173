import { handleException } from '@/common/exceptions/exception-handling';
import { displayData, formatFullDate } from '@/common/helpers';
import * as toast from '@/common/helpers/toast';
import AppButton from '@/components/atom/Button/Button';
import { useLoading } from '@/hooks/useLoading';
import { IColumns } from '@/module/adminUsers/type.interface';
import { InterviewsApi } from '@/module/interviews/api';
import { ColumnInterviewsId, EInterviewStatus, INTERVIEW_STATUS, PAYMENT_STATUS } from '@/module/interviews/constants';
import { IInterviewsResponse } from '@/module/interviews/response/interviews.interface';
import React from 'react';
import ConfirmInterviewPopup from './ConfirmInterviewPopup';
import ConfirmPaymentPopup from './ConfirmPaymentPopup';
import InformationPopup from './InformationPopup';

interface TableInterviewsProps {
  columns: IColumns[];
  data: IInterviewsResponse[];
  setData: React.Dispatch<React.SetStateAction<IInterviewsResponse[]>>;
}

const TableInterviews: React.FC<TableInterviewsProps> = ({ columns, setData, data }) => {
  const [currentData, setCurrentData] = React.useState<any>({});
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openMaxPopup, setOpenMaxPopup] = React.useState(false);
  const [openPaymentPopup, setOpenPaymentPopup] = React.useState(false);
  const [openInfomationPopup, setOpenInfomationPopup] = React.useState(false);
  const { openLoading, closeLoading } = useLoading();

  const updateInterviewStatus = (status: EInterviewStatus) => {
    const newData = data.map((item) => {
      if (item.id === currentData.id) {
        return {
          ...item,
          interviewStatus: status,
        };
      }
      return item;
    });
    setData([...newData]);
  };

  const handleConfirmInterviewsStatusPopup = async () => {
    try {
      openLoading();
      const response = await InterviewsApi.approveInterview(currentData.id);
      if (response.status === 200) {
        updateInterviewStatus(EInterviewStatus.APPROVED);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const handleFailureInterviewsStatusPopup = async () => {
    try {
      openLoading();
      const response = await InterviewsApi.cancelInterview(currentData.id);
      if (response) {
        updateInterviewStatus(EInterviewStatus.DENIED);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const handleConfirmPayment = async () => {
    try {
      openLoading();
      const response = await InterviewsApi.paid(currentData.id);
      if (response) {
        updateInterviewStatus(EInterviewStatus.PAID);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      setOpenPaymentPopup(false);
      closeLoading();
    }
  };

  const handleShowInterviewsStatusPopup = (data: IInterviewsResponse, type: ColumnInterviewsId) => {
    if (ColumnInterviewsId.PASS_FAIL === type) setOpenPopup(true);
    if (ColumnInterviewsId.PAYMENT === type) setOpenPaymentPopup(true);
    if (ColumnInterviewsId.SUPPORTER_NAME === type) setOpenInfomationPopup(true);
    setCurrentData(data);
  };

  return (
    <div className="flex max-h-table border-separate flex-col overflow-clip rounded-xl border border-outline-200 bg-white px-4 py-9">
      <table className="w-full table-fixed">
        <thead className="sticky top-0">
          <tr>
            {columns.map((column) => (
              <th id={column.id} key={column.id} className={column.className}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div className="flex-1 overflow-y-auto">
        <table className="w-full table-fixed">
          {data.length ? (
            <tbody>
              <>
                {data.map((row, index) => (
                  <tr className="odd:bg-plate-300" key={index}>
                    <td className="w-[26%] truncate p-2 text-left text-base font-medium text-secondary-400">
                      {displayData(row.returnName)}
                    </td>
                    <td className="w-[26%] truncate p-2 text-left text-base font-medium text-secondary-400">
                      {displayData(row.email)}
                    </td>
                    <td
                      className="w-[15%] cursor-pointer truncate p-2 text-left text-base font-medium text-secondary-400"
                      onClick={() => handleShowInterviewsStatusPopup(row, ColumnInterviewsId.SUPPORTER_NAME)}
                    >
                      {displayData(row.nickName)}
                    </td>
                    <td className="w-[23%] p-2 text-left text-base font-medium text-secondary-400">
                      {formatFullDate(row.interviewDate)}
                    </td>
                    <td className="w-[15%] p-2 text-left text-sm font-bold">
                      {row.interviewStatus === EInterviewStatus.PENDING ? (
                        <>
                          <AppButton
                            onClick={() => handleShowInterviewsStatusPopup(row, ColumnInterviewsId.PASS_FAIL)}
                            text="判定"
                            size="s-md"
                            custome="text-sm"
                          />
                        </>
                      ) : (
                        <>{displayData(INTERVIEW_STATUS?.[row.interviewStatus as keyof typeof INTERVIEW_STATUS])}</>
                      )}
                    </td>
                    <td className="w-[15%] p-2 text-left text-sm font-bold">
                      {row.interviewStatus === EInterviewStatus.APPROVED ? (
                        <>
                          <AppButton
                            text="未払い"
                            size="s-md"
                            onClick={() => handleShowInterviewsStatusPopup(row, ColumnInterviewsId.PAYMENT)}
                            custome="text-sm"
                          />
                        </>
                      ) : (
                        <>{displayData(PAYMENT_STATUS?.[row.interviewStatus as keyof typeof PAYMENT_STATUS])}</>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          ) : (
            <>
              <div className="truncate py-10 text-center text-2xl font-bold text-secondary-400">データ無し</div>
            </>
          )}
        </table>
      </div>
      <ConfirmInterviewPopup
        openMaxPopup={openMaxPopup}
        openPopup={openPopup}
        setOpenMaxPopup={setOpenMaxPopup}
        setOpenPopup={setOpenPopup}
        currentData={currentData}
        handleApprove={handleConfirmInterviewsStatusPopup}
        handleFailure={handleFailureInterviewsStatusPopup}
      />
      <ConfirmPaymentPopup
        openPopup={openPaymentPopup}
        setOpenPopup={setOpenPaymentPopup}
        handleConfirm={handleConfirmPayment}
      />
      <InformationPopup
        openPopup={openInfomationPopup}
        setOpenPopup={setOpenInfomationPopup}
        currentData={currentData}
      />
    </div>
  );
};

export default TableInterviews;
