import { IDetailData } from "@/module/users/type.interface";
import React from "react";

interface RowDetailProps {
  data: IDetailData;
}

const RowDetail: React.FC<RowDetailProps> = ({ data }) => {
  return <div className="w-full border-b-border-blue border-b-[1px]">
    <p className="text-primary-400 font-bold text-base leading-5 flex justify-starts text-ellipsis line-clamp-1">{data.label}</p>
    <p className="text-neutral-850 font-bold text-sm leading-[17px] flex justify-end text-ellipsis line-clamp-1 mb-1">{data.value}</p>
  </div>;
};

export default RowDetail;
