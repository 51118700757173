import { IColumns } from "../adminUsers/type.interface";

export enum ColumnUsersId {
    NAME = "name",
    EMAIL = "email",
    REGISTER_DATE = "register_date",
    USER_NAME = "user_name",
    DETAIL = "detail",
    DELETE = "delete",
  }
  
export const USERS_COLUMNS: IColumns[] = [
    {
      id: ColumnUsersId.NAME,
      label: "名前",
      className: "text-left p-2.5 w-[26%]",
    },
    {
      id: ColumnUsersId.EMAIL,
      label: "メールアドレス",
      className: "text-left p-2.5 w-[26%]",
    },
    {
      id: ColumnUsersId.USER_NAME,
      label: "ユーザー名",
      className: "text-left p-2.5 w-[17%]",
    },
    {
      id: ColumnUsersId.REGISTER_DATE,
      label: "登録日",
      className: "text-left p-2.5 w-[21%]",
    },
    {
      id: ColumnUsersId.DETAIL,
      label: "詳細",
      className: "text-left p-2.5 w-[15%]",
    },
    {
      id: ColumnUsersId.DELETE,
      label: "削除",
      className: "text-left p-2.5 w-[15%]",
    },
  ];

  export enum LabelDetail {
    NAME = "ニックネーム",
    EMAIL = "email",
    DOB = "生年月日",
    PHONE = "電話番号",
    USER_NAME = "ユーザー名",
    SEX = "性別",
    LANGUAGE = "言語"
  }
  
  export const SEX_CODE = {
    0: "男性",
    1: "女性",
    2: "その他"
  }

  export const LANGUAGE_CODE = {
    "ja": "日本語",
    "en": "英語"
  }