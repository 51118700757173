import { useLoading } from '@/hooks/useLoading';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { IPopupProps } from '../../../interfaces/confirmPopup.interface';
import AppButton from '../../atom/Button/Button';
import './index.css';

const ConfirmPopup = (props: IPopupProps) => {
  const { children, popupConfig, className } = props;
  const { isOpenLoading } = useLoading();

  return (
    <Dialog.Root open={popupConfig.open}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-blackA6 data-[state=open]:animate-overlayShow" />
        <Dialog.Content
          className={`${className} fixed left-[50%] top-[50%] max-h-[85vh] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white p-[25px] shadow-popup focus:outline-none data-[state=open]:animate-contentShow`}
        >
          {popupConfig.title && (
            <Dialog.Title className="m-0 flex items-center justify-between text-xl font-bold text-neutral-850">
              {popupConfig.title}
              {popupConfig.isShowCloseButton && (
                <Dialog.Close asChild>
                  <button onClick={popupConfig.onClose} className="" aria-label="Close">
                    <Cross2Icon />
                  </button>
                </Dialog.Close>
              )}
            </Dialog.Title>
          )}
          {popupConfig.description && (
            <Dialog.Description className="mb-6 mt-4 text-base text-neutral-850">
              {popupConfig.description}
            </Dialog.Description>
          )}
          {popupConfig.children && (
            <Dialog.Description className="mb-6 mt-4">{popupConfig.children}</Dialog.Description>
          )}
          <div className="flex justify-end gap-[14px]">
            {popupConfig.cancelText && (
              <Dialog.Close asChild>
                <AppButton
                  onFocus={() => null}
                  onClick={popupConfig.onCancel}
                  text={popupConfig.cancelText}
                  variant="outline"
                  size="sm"
                  custome="text-xs px-0 flex-1"
                  disabled={isOpenLoading}
                ></AppButton>
              </Dialog.Close>
            )}
            {popupConfig.confirmText && (
              <Dialog.Trigger asChild>
                <AppButton
                  onClick={popupConfig.onConfirm}
                  text={popupConfig.confirmText}
                  size="sm"
                  custome="px-0 flex-1"
                  disabled={isOpenLoading}
                ></AppButton>
              </Dialog.Trigger>
            )}
            {popupConfig.customeBtn && <Dialog.Trigger asChild>{popupConfig.customeBtn}</Dialog.Trigger>}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ConfirmPopup;
