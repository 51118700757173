import AppButton from "@/components/atom/Button/Button";
import Heading1 from "@/components/common/Heading1";
import React, { useEffect, useState } from "react";
import ProjectCard from "./components/ProjectCard/ProjectCard";
import { IProjectsListResponse } from "@/module/projectsList/response/projectsList.interface";
import { ProjectsListApi } from "@/module/projectsList/api";
import { useLoading } from "@/hooks/useLoading";
import { useNavigate } from "react-router-dom";
import * as toast from "@/common/helpers/toast";
import { handleException } from "@/common/exceptions/exception-handling";
import { EStatusProject } from "@/module/projectsList/constants";

interface ProjectsListPageProps {}

const ProjectsListPage: React.FC<ProjectsListPageProps> = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<IProjectsListResponse[]>([]);
  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    fetchProjectsData();
  }, []);

  const fetchProjectsData = async () => {
    try {
      openLoading();
      const response = await ProjectsListApi.getAllProjects();
      if (response?.data) {
        setProjects(response?.data);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const goToDetail = (data: IProjectsListResponse) => {
    if (data.status === EStatusProject.DRAFT)
      return navigate(`/projects/${data.id}/draft`);
    navigate(`/projects/${data.id}/dashboard`);
  };

  const goToPreview = (projectId: string) => {
    navigate(`/projects/${projectId}/preview`);
  };

  const handleClickCreate = () => {
    navigate(`/projects/create`);
  };

  return (
    <div className="px-20 lg:px-[4.25rem] xl:px-20 py-16">
      <Heading1 text="ホーム" />
      <div className="mt-10 mb-14">
        <div className="flex justify-end">
          <AppButton
            text="プロジェクトを新規作成"
            size="lg"
            onClick={handleClickCreate}
          />
        </div>
      </div>
      <div className="flex gap-8 flex-wrap">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            data={project}
            onPreview={goToPreview}
            onDetail={goToDetail}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectsListPage;
