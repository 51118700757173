import ConfirmPopup from '@/components/modals/ConfirmPopup';
import React from 'react';

export interface IConfirmPaymentPopupProps {
  openPopup: boolean;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm: Function;
}

const ConfirmPaymentPopup = ({ openPopup, setOpenPopup, handleConfirm }: IConfirmPaymentPopupProps) => {
  const popupConfig = {
    title: '支払済み判定',
    confirmText: '支払い済み',
    cancelText: 'キャンセル',
    children: (
      <div className="mb-6 mt-4 text-base font-medium text-secondary-500">
        <p>このユーザーは支払いが完了しましたか？</p>
        <p>支払済みにするとユーザーにリターンが送付されます</p>
      </div>
    ),
    onCancel: () => setOpenPopup(false),
    onConfirm: () => onClickConfirm(),
    open: openPopup,
  };

  const onClickConfirm = () => {
    setOpenPopup(false);
    handleConfirm();
  };

  return (
    <>
      <ConfirmPopup popupConfig={popupConfig} />
    </>
  );
};

export default ConfirmPaymentPopup;
