import { IColumns } from './type.interface';

enum ColumnId {
  CHECKBOX = 'checkbox',
  USER_NAME = 'user_name',
  EMAIL = 'email',
  NICK_NAME = 'nick_name',
  REGISTER_DATE = 'register_date',
}

export const SEND_RETURN_USERS_COLUMNS: IColumns[] = [
  {
    id: ColumnId.CHECKBOX,
    label: '選択',
    className: 'text-left p-2.5 w-[11%]',
  },
  {
    id: ColumnId.USER_NAME,
    label: '名前',
    className: 'text-left p-2.5 w-[30%]',
  },
  {
    id: ColumnId.EMAIL,
    label: 'メールアドレス',
    className: 'text-left p-2.5 w-[30%]',
  },
  {
    id: ColumnId.NICK_NAME,
    label: 'ユーザー名',
    className: 'text-left p-2.5 w-[14%]',
  },
  {
    id: ColumnId.REGISTER_DATE,
    label: '登録日',
    className: 'text-left p-2.5 w-[15%]',
  },
];
