import React from "react";
import { FieldError } from "react-hook-form";
import { TextArea } from "@radix-ui/themes";

interface AppTextAreaProps {
  error?: FieldError | undefined;
  placeholder?: string;
  value?: string;
  custome?: string;
  onChange?: (...event: any[]) => void;
  disabled?: boolean;
}

const AppTextArea = React.forwardRef<HTMLInputElement, AppTextAreaProps>(
  ({
    value,
    custome = "h-[110px]",
    placeholder,
    error,
    onChange,
    disabled,
  }) => {
    return (
      <TextArea
        onChange={onChange}
        defaultValue={value}
        className={`${custome} ${error ? "no-outline-important border-2 border-primary-500" : ""}`}
        radius="full"
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  }
);

export default AppTextArea;
